import { useContext } from "react";
import { HomeContext } from "../containers/Home";
import { getWellStatusTypeList } from "../services/api-server/status_types";
import { getWellStatusList } from "../services/api-server/status";
import { getWellPurposeTypeList } from "../services/api-server/purpose_types";
import { getWellPurposeList } from "../services/api-server/purposes";
import { getProjectTagList } from "../services/api-server/project_tags";

function useUpdateAllData() {
    const context: any = useContext(HomeContext);

    const updateAllData = (
        data: any,
        type: any,
        lookupKey: any,
        formOption: any = null
    ) => {

        const specialCases = [
            "well_purpose",
            "well_purpose_type",
            "well_status",
            "well_status_type",
            "project_tags",
        ];

        if (formOption) {
            lookupKey = formOption;
        }
        switch (lookupKey) {
            case "roles_permissions":
                lookupKey = "roles";
                break;
            case "tag":
                lookupKey = "project_tags";
                break;
        }
        if (!specialCases.includes(lookupKey)) {
            let index = -1;
            switch (type) {
                case "new":
                    if (context.allData[lookupKey]) {
                        context.allData[lookupKey].push(data);
                    } else {
                        context.allData[lookupKey] = [data];
                    }
                    break;
                case "update":
                    index = context.allData[lookupKey].findIndex(
                        (item: any) => item._id === data._id
                    );

                    if (index != -1) {
                        context.allData[lookupKey][index] = data;
                    }
                    break;
                case "delete":
                    console.log(data);
                    index = context.allData[lookupKey].findIndex(
                        (item: any) => item._id === data._id
                    );
                    console.log(index);
                    if (index != -1) {
                        context.allData[lookupKey].splice(index, 1);
                    }
                    break;
            }
        } else if (specialCases.includes(lookupKey)) {
            switch (lookupKey) {
                case "project_tags":
                    getProjectTagList(context.currentTenantKey).then((_data: any) => {
                        if (_data) {
                            context.allData[lookupKey] = _data;
                        }
                    });
                    break;
                case "well_purpose":
                case "well_purpose_type":
                    getWellPurposeList().then((_data: any) => {
                        if (_data) {
                            context.allData["well_purpose"] = _data;
                        }
                    });
                    getWellPurposeTypeList().then((_data: any) => {
                        if (_data) {
                            context.allData["well_purpose_type"] = _data;
                        }
                    });
                    break;
                case "well_status":
                case "well_status_type":
                    getWellStatusList().then((_data: any) => {
                        if (_data) {
                            context.allData["well_status"] = _data;
                        }
                    });
                    getWellStatusTypeList().then((_data: any) => {
                        if (_data) {
                            context.allData["well_status_type"] = _data;
                        }
                    });
                    break;
            }
        }
    };

    return { updateAllData };
}

export default useUpdateAllData;