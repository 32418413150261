import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { SearchOutlined } from "@ant-design/icons";
import {
	Space,
	Divider,
	Input,
	Switch,
	Button,
	Empty,
	Table,
	Image,
	Modal,
	Form,
	Upload,
	List,
	Popover,
	Dropdown,
	TableProps,
	Tooltip,
	Spin,
	Tag,
	Checkbox,
	Progress,
} from "antd";
import context from "antd/es/app/context";
import dayjs from "dayjs";
import GanttChart from "../components/Charts/GanttChart";
import CheckboxDropdown from "../components/CheckboxDropdown";
import AuditLogModal from "../components/Modal/AuditLogModal";
import SearchButton from "../components/SearchButton";
import { GetAntIcon } from "../utils/ant_icons";
import { capitalizeFirstLetter, checkPrivilege } from "../utils/utils";
import NoAccess from "./NoAccess";
import { useLocation, useNavigate } from "react-router";
import { HomeContext } from "./Home";
import Emitter from "../utils/emitter";
// import "../assets/css/auditlog.css";
// import "../assets/css/welldocs.css";
import { useForm } from "antd/es/form/Form";
import {
	getFilePreview,
	getFileThumbnail,
	collabo,
	createNewFolder,
	downloadFile,
	listFolderItems,
	uploadFiles,
	getRootFolderId,
	deleteFiles,
	renameFile,
	getFileMetadata,
	getFile,
} from "../services/api-server/welldocs";
import fileTools from "../utils/fileTools";
import { getWells } from "../services/api-server/wells";
import WellConfigurationModal from "../components/Modal/WellConfigurationModal";
import { setLookupData } from "../utils/lookupFinder";
import { getOperations } from "../services/api-server/operations";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import { getCampaignBasedWellProjects } from "../services/api-server/well_projects";
import elementalLoadingLogo from "../assets/image/elemental 'e'.svg";
import elementalLoadingOutline from "../assets/image/outline 'e'.svg";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { socket } from "../utils/socket";

const WellDetails = (props: any) => {
	const [formRef] = useForm();
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	const { handleContextMenu } = useHandleContextMenu();

	const [wellDetailsExpand, setWellDetailsExpand] = useState<boolean>(false);
	const [wellInfo, setWellInfo] = useState<any>(null);
	const [wellOperationList, setWellOperationList] = useState<any>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [activePage, setActivePage] = useState<string>("operations");

	//Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [descMode, setDescMode] = useState<boolean>(false);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		location: [],
		well_purpose: [],
		well_status: [],
	});

	//document search
	const [searchRecord, setSearchRecord] = useState<any>([]);
	const [filteredItems, setFilteredItems] = useState<any>([]);

	//Folder Modal
	const [folderModalOpen, setFolderModalOpen] = useState<boolean>(false);
	const [folderName, setFolderName] = useState<string>("");
	//File Modal
	// const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
	const [fileList, setFileList] = useState<any>([]);
	const [uploadLength, setUploadLength] = useState<any>(0);
	//Rename Modal
	const [renameModalOpen, setRenameModalOpen] = useState<boolean>(false);
	const [tempFileName, setTempFileName] = useState<string>("");
	const [newFileName, setNewFileName] = useState<string>("");
	const [tempRecord, setTempRecord] = useState<any>({});
	//Confirmation Modal
	const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const [isDeleteMultiple, setIsDeleteMultiple] = useState<boolean>(false);

	const [confirmUploadModalOpen, setConfirmUploadModalOpen] =
		useState<boolean>(false);

	//Loading Modal
	const [loadingModalOpen, setLoadingModalOpen] = useState<boolean>(false);
	const [actionType, setActionType] = useState<string>("");
	const [loadingPercentage, setLoadingPercentage] = useState<number>(0);

	const [tenantKey, setCurrentTenantKey] = useState<any>(null);
	const [rootFolderId, setRootFolderId] = useState<any>(null);
	const [currentFolderFiles, setCurrentFolderFiles] = useState<any>([]);
	const [currentFolderId, setCurrentFolderId] = useState<any>(null);
	const [containerId, setCurrentContainerId] = useState<any>(null);
	const [wellId, setCurrentWellId] = useState<any>(null);

	const [ownerList, setOwnerList] = useState<any>([]);
	const [quickAccessFiles, setQuickAccessFiles] = useState<any>([]);
	const [quickAccessFileMetaData, setQuickAccessFileMetaData] = useState<any>(
		{}
	);
	const [quickAccessCollapsed, setQuickAccessCollapsed] =
		useState<boolean>(false);

	const [rowSelectedIDs, setRowSelectedIDs] = useState<any>([]);
	const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
		"checkbox"
	);

	const [folderHierarchy, setFolderHierarchy] = useState<any>([]);
	const [openWellModal, setOpenWellModal] = useState<boolean>(false);

	useEffect(() => {
		Emitter.emit("loading", true);

		if (context.currentTenantKey && context.allData) {
			let well_id: any =
				location?.state?.well_id || location?.pathname?.split("/")[2];

			getWells(context.currentTenantKey, { _id: well_id }).then(
				(wells: any) => {
					setWellInfo(wells[0]);
					context.setCurrentWell(wells[0]);

					getOperations(context.currentTenantKey, {
						well_id: wells[0]._id,
					}).then((operations: any) => {
						setWellOperationList(operations);
					});
				}
			);
			setIsLoading(false);

			let tenantKey = context.currentTenantKey;
			let container_id = context.currentTenantData?.container_id;
			try {
				getRootFolderId(tenantKey, well_id).then((response: any) => {
					console.log(response);
					let folder_id = response;

					listFolderItems(tenantKey, well_id, folder_id, container_id).then(
						(response: any) => {
							setCurrentTenantKey(tenantKey);

							setCurrentFolderFiles(response.items || []);
							setFilteredItems(response.items || []);
							setCurrentWellId(well_id);
							setRootFolderId(folder_id || response.folderid);
							setCurrentFolderId(folder_id || response.folderid);
							setCurrentContainerId(
								context.currentTenantData?.container_id || response.containerid
							);
							Emitter.emit("loading", false);
						}
					);
				});
				getFileMetadata(tenantKey, well_id).then((response: any) => {
					const quickAccessFiles: any = response
						.filter((item: any) => item.item_type === "file")
						.sort(
							(a: any, b: any) =>
								new Date(b.createdAt).getTime() -
								new Date(a.createdAt).getTime()
						) // Sort by createdDate (descending)
						.slice(0, 10);
					setQuickAccessFiles(quickAccessFiles);
					setOwnerList(response);
				});
			} catch (error: any) {
				console.log(error);
				Emitter.emit("loading", false);
			}
		}

		socket.on("ACTION_PROGRESS", (payload: any) => {
			console.log(payload);
			setLoadingPercentage(payload);
		});

		Emitter.on("ACTION-PROGRESS", (percentage: any) => {
			console.log(percentage);
			setLoadingPercentage(percentage);
		});

		return () => {
			socket.removeListener("ACTION_PROGRESS");
			Emitter.remove("ACTION-PROGRESS");
		};
	}, [context.currentTenantKey, context.allData]);

	useEffect(() => {
		if (containerId) {
			// Fetch the images for each file
			const fetchImages = async () => {
				const fileMetaData: any = {};

				await Promise.all(
					quickAccessFiles.map(async (file: any) => {
						try {
							// Call your API to get the image URL for each file
							const thumbnailResponse: any = await getFileThumbnail(
								context.currentTenantKey,
								file.item_id,
								containerId
							);
							const fileResponse: any = await getFile(
								context.currentTenantKey,
								file.item_id,
								containerId
							);
							fileMetaData[file.item_id] = {
								name: fileResponse?.name,
								filetype: fileResponse.file?.mimeType || "undefined",
								thumbnail_url: thumbnailResponse.value[0]?.medium?.url,
								createdDateTime: fileResponse.createdDateTime,
							};
							// Assuming `data.imageUrl` contains the image URL
						} catch (error: any) {
							console.error(
								`Failed to fetch image for file ${file.item_id}`,
								error
							);
							fileMetaData[file.item_id] = null; // Set a fallback image in case of error
						}
					})
				);

				setQuickAccessFileMetaData(fileMetaData);
			};

			fetchImages();
		}
	}, [quickAccessFiles, containerId]);

	// Handle file uploads
	useEffect(() => {
		if (
			fileList.length > 0 &&
			uploadLength > 0 &&
			fileList.length === uploadLength
		) {
			//Check for filename duplicates
			let filenames = fileList.map((file: any) => file.name);
			if (filenames) {
				console.log(filenames, currentFolderFiles);
				if (
					currentFolderFiles.some((file: any) => filenames.includes(file.name))
				) {
					setConfirmUploadModalOpen(true);
				} else {
					handleFileUpload(fileList);
				}
			}
		}
	}, [uploadLength, fileList]);

	const handleFolderChange = (
		folderID: any,
		folderName: string,
		index: any = null
	) => {
		Emitter.emit("loading", true);
		try {
			listFolderItems(tenantKey, wellId, folderID, containerId).then(
				(response: any) => {
					if (index == null) {
						setFolderHierarchy([
							...folderHierarchy,
							{ id: folderID, name: folderName },
						]);
					} else if (index == -1) {
						setFolderHierarchy([]);
					} else {
						let array = [...folderHierarchy].slice(0, index + 1);

						setFolderHierarchy(array);
					}

					setRowSelectedIDs([]);
					setFilteredItems(response.items);
					setSearchRecord([]);
					setCurrentFolderFiles(response.items);
					setCurrentFolderId(folderID);
					Emitter.emit("loading", false);
				}
			);
		} catch {
			Emitter.emit("loading", false);
		}
	};

	const handleBeforeUpload = (file: any) => {
		// Prevent the file from being uploaded immediately
		setFileList((prevList: any) => [...prevList, file]);
		return false; // This stops the upload process
	};
	const handleRemove = (file: any) => {
		setFileList((prevList: any) =>
			prevList.filter((item: any) => item.uid !== file.uid)
		);
	};

	//Setting search record
	const handleSearch = (value: any) => {
		console.log(value);

		let record: any = [...searchRecord];
		let recordIndex = record.findIndex((item: any) => {
			return item.columnKey === value.columnKey;
		});
		if (recordIndex !== -1) {
			if (value.value != "") {
				record[recordIndex] = value;
				setSearchRecord(record);
			} else {
				record.splice(recordIndex, 1);
				setSearchRecord(record);
			}
		} else {
			if (value.value != "") {
				record.push(value);
				setSearchRecord(record);
			}
		}

		//Filter data and table columns based on search record
		let data: any = [...currentFolderFiles];
		let filteredData = data
			?.map((element: any) => {
				let bool = record.every((item: any) => {
					if (Array.isArray(element[item.columnKey])) {
						return element[item.columnKey].some((arrayItem: any) =>
							arrayItem.toLowerCase().includes(item.value.toLowerCase())
						);
					} else {
						return element[item.columnKey]
							?.toLowerCase()
							.includes(item.value.toLowerCase());
					}
				});
				if (bool) return element;
			})
			.filter((element: any) => element !== null && element !== undefined);
		setFilteredItems(filteredData);
		data = filteredData;
	};

	const handleFolderCreation = () => {
		let new_name = folderName;
		if (
			currentFolderFiles?.some(
				(file: any) => file.name.toLowerCase() === new_name.toLowerCase()
			)
		) {
			Emitter.emit("alert", {
				type: "error",
				message: `The folder name has already exist, please ensure the folder name is unique. Changes are not saved.`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
		} else {
			let userInfo = context.allData.userInfo.user;
			let user = { email: userInfo.email, id: userInfo._id };
			let folderHierarchyIds = folderHierarchy.map((folder: any) => folder.id);
			Emitter.emit("loading", true);
			try {
				createNewFolder(
					new_name,
					context.currentTenantKey,
					wellId,
					currentFolderId,
					containerId,
					folderHierarchyIds,
					user
				)
					.then((response: any) => {
						listFolderItems(
							context.currentTenantKey,
							wellId,
							currentFolderId,
							containerId
						).then((response: any) => {
							setCurrentFolderFiles(response.items || []);
							setFilteredItems(response.items || []);
							setFolderModalOpen(false);
							setFolderName("");
							Emitter.emit("loading", false);
						});
						getFileMetadata(tenantKey, wellId).then((response: any) => {
							const quickAccessFiles: any = response
								.filter((item: any) => item.item_type === "file")
								.sort(
									(a: any, b: any) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								) // Sort by createdDate (descending)
								.slice(0, 10);
							setQuickAccessFiles(quickAccessFiles);
							setOwnerList(response);

							Emitter.emit("alert", {
								type: "success",
								message: `Folder has been successfully created`,
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						});
					})
					.catch((error: any) => {
						if (error.code === "nameAlreadyExists") {
							Emitter.emit("loading", false);
							Emitter.emit("alert", {
								type: "error",
								message: `The folder name has already exist, please ensure the folder name is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} catch {
				setFolderModalOpen(false);
				setFolderName("");
				Emitter.emit("alert", {
					type: "error",
					message: `Folder could not be created`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
				Emitter.emit("loading", false);
			}
		}
	};

	const handleFileUpload = (fileList: any) => {
		let userInfo = context.allData.userInfo.user;
		let user = { email: userInfo.email, id: userInfo._id };
		// Emitter.emit("loading", true);
		setActionType("upload");
		setLoadingPercentage(0);
		setLoadingModalOpen(true);
		let folderHierarchyIds = folderHierarchy.map((folder: any) => folder.id);
		try {
			uploadFiles(
				fileList,
				context.currentTenantKey,
				wellId,
				currentFolderId,
				containerId,
				folderHierarchyIds,
				user,
				socket.id
			).then((response: any) => {
				setFileList([]);
				setLoadingModalOpen(false);
				listFolderItems(
					context.currentTenantKey,
					wellId,
					currentFolderId,
					containerId
				).then((response: any) => {
					// setFileModalOpen(false);
					setCurrentFolderFiles(response.items || []);
					setFilteredItems(response.items || []);

					Emitter.emit("loading", false);

					Emitter.emit("alert", {
						type: "success",
						message: `${fileList.length} file(s) have been successfully uploaded`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
				getFileMetadata(tenantKey, wellId).then((response: any) => {
					const quickAccessFiles: any = response
						.filter((item: any) => item.item_type === "file")
						.sort(
							(a: any, b: any) =>
								new Date(b.createdAt).getTime() -
								new Date(a.createdAt).getTime()
						) // Sort by createdDate (descending)
						.slice(0, 10);
					setQuickAccessFiles(quickAccessFiles);
					setOwnerList(response);
				});
			});
		} catch (error: any) {
			setFileList([]);
			setLoadingModalOpen(false);
			Emitter.emit("alert", {
				type: "error",
				message: `${fileList.length} file(s) could not be uploaded`,
				description: "",
				top: true,
				closeable: false,
				timeout: 3000,
			});
			// setFileModalOpen(false);
			// Emitter.emit("loading", false);
		}
	};

	const handleFileRename = () => {
		try {
			let userInfo = context.allData.userInfo.user;
			let user = { email: userInfo.email, id: userInfo._id };
			let new_name = newFileName + tempRecord?.fileSuffix;
			let count = 1;
			if (
				tempRecord.folder &&
				currentFolderFiles?.some(
					(file: any) =>
						file.name.toLowerCase() === new_name.toLowerCase() &&
						file.id != tempRecord.id &&
						file.folder
				)
			) {
				Emitter.emit("alert", {
					type: "error",
					message: `The folder name has already exist, please ensure the folder name is unique. Changes are not saved.`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			} else {
				if (tempRecord.file) {
					Emitter.emit("loading", true);
					while (
						currentFolderFiles?.some(
							(file: any) =>
								file.name.toLowerCase() === new_name.toLowerCase() &&
								file.id != tempRecord.id &&
								file.file
						)
					) {
						const lastDotIndex = new_name.lastIndexOf(".");
						if (lastDotIndex !== -1) {
							new_name =
								`${new_name.substring(0, lastDotIndex)} (${count})` +
								tempRecord?.fileSuffix;
						} else {
							new_name = newFileName + ` (${count})` + tempRecord?.fileSuffix;
						}
						count++;
					}
				}

				renameFile(
					context.currentTenantKey,
					tempRecord.id,
					containerId,
					new_name,
					user
				)
					.then((response: any) => {
						let foundRootIndex = currentFolderFiles.findIndex(
							(record: any) => record.id === response.id
						);
						let foundFilteredIndex = filteredItems.findIndex(
							(record: any) => record.id === response.id
						);
						if (foundRootIndex != -1) {
							let _newFolderFiles = [...currentFolderFiles];
							_newFolderFiles[foundRootIndex] = response;
							setCurrentFolderFiles(_newFolderFiles || []);
						}
						if (foundFilteredIndex != -1) {
							let _newFilteredFiles = [...filteredItems];
							_newFilteredFiles[foundFilteredIndex] = response;
							setFilteredItems(_newFilteredFiles || []);
						}

						setRenameModalOpen(false);
						setTempFileName("");
						setNewFileName("");
						setTempRecord({});
						Emitter.emit("loading", false);
					})
					.catch((error: any) => {
						if (error.code === "nameAlreadyExists") {
							Emitter.emit("loading", false);
							Emitter.emit("alert", {
								type: "error",
								message: `The name has already exist, please try another.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		} catch (error: any) {
			setRenameModalOpen(false);
			setTempFileName("");
			setNewFileName("");
			setTempRecord({});
			Emitter.emit("loading", false);
		}
	};

	const handleFileDelete = (
		idList: any,
		multiple: boolean,
		record: any = null,
		filetype: any = null,
		index: any = -1
	) => {
		// Emitter.emit("loading", true);
		setDeleteModalOpen(false);
		setLoadingModalOpen(true);
		setActionType("delete");
		setLoadingPercentage(0);
		deleteFiles(tenantKey, idList, containerId, socket.id)
			.then((response: any) => {
				if (multiple) {
					let tempFolderFiles = [...filteredItems];
					let _newFolderFiles = [...currentFolderFiles];
					let tempQuickAccessFiles = [...quickAccessFiles];

					for (const fileID of rowSelectedIDs) {
						let foundRootIndex = _newFolderFiles.findIndex(
							(record: any) => record.id === fileID
						);

						let foundFilteredIndex = tempFolderFiles.findIndex(
							(record: any) => record.id === fileID
						);
						if (foundRootIndex != -1) {
							_newFolderFiles.splice(foundRootIndex, 1);
						}
						if (foundFilteredIndex != -1) {
							tempFolderFiles.splice(foundFilteredIndex, 1);
						}
					}

					setCurrentFolderFiles(_newFolderFiles || []);
					setFilteredItems(tempFolderFiles);
					setRowSelectedIDs([]);
				} else {
					let foundRootIndex = currentFolderFiles.findIndex(
						(record: any) => record.id === response.id
					);
					if (foundRootIndex != -1) {
						let _newFolderFiles = [...currentFolderFiles];
						_newFolderFiles.splice(foundRootIndex, 1);
						setCurrentFolderFiles(_newFolderFiles || []);
					}

					let tempFolderFiles = [...filteredItems];
					tempFolderFiles.splice(index, 1);

					setFilteredItems(tempFolderFiles);
					setRowSelectedIDs([]);
				}

				setLoadingModalOpen(false);
				getFileMetadata(tenantKey, wellId).then((response: any) => {
					const quickAccessFiles: any = response
						.filter((item: any) => item.item_type === "file")
						.sort(
							(a: any, b: any) =>
								new Date(b.createdAt).getTime() -
								new Date(a.createdAt).getTime()
						) // Sort by createdDate (descending)
						.slice(0, 10);
					setQuickAccessFiles(quickAccessFiles);
					setOwnerList(response);
				});
				// Emitter.emit("loading", false);

				Emitter.emit("alert", {
					type: "success",
					message: `${
						multiple
							? `${rowSelectedIDs.length} checked item(s) have`
							: `${capitalizeFirstLetter(filetype)} has`
					} been successfully deleted`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			})
			.catch(() => {
				setDeleteModalOpen(false);
				setLoadingModalOpen(false);
				Emitter.emit("loading", false);
				Emitter.emit("alert", {
					type: "error",
					message: `${
						multiple
							? `${rowSelectedIDs.length} checked item(s)`
							: `${capitalizeFirstLetter(filetype)}`
					} could not be deleted`,
					description: "",
					top: true,
					closeable: false,
					timeout: 3000,
				});
			});
	};

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	const filterRender = useMemo(() => {
		const handleMenuClick = (field: any, selectedValues: any[]) => {
			// console.log(`Selected: ${field} - ${selectedValues}`);
			// Update the selected filters state
			setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

			localStorage.setItem(
				"selectedFilters",
				JSON.stringify({ ...selectedFilters, [field]: selectedValues })
			);
		};

		const handleClearAllFilters = () => {
			// Reset all filters to empty arrays
			setSelectedFilters({
				location: [],
				well_purpose: [],
				well_status: [],
			});
			setFilterMode(false);

			// Clear session storage for filter values and checkboxes
			localStorage.removeItem("selectedFilters");

			// Object.keys(selectedFilters).forEach((field) => {
			// 	const storageKey = generateStorageKey(field);
			// 	localStorage.removeItem(storageKey);
			// });

			setTimeout(() => {
				setFilterMode(true);
			}, 5);
		};

		return (
			<>
				{" "}
				<CheckboxDropdown
					options={[
						{
							label: "onshore",
							value: "onshore",
						},
						{ label: "offshore", value: "offshore" },
					]}
					onChange={(selectedValues) =>
						handleMenuClick("location", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters.location}
					Key="location"
					label="Location"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_purpose?.map((wp: any) => ({
						label: wp.name,
						value: wp.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_purpose", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_purpose"]}
					Key="well_purpose"
					label="Well purpose"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_status?.map((ws: any) => ({
						label: ws.name,
						value: ws.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_status", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_status"]}
					Key="well_status"
					label="Well status"
				/>
				<div className="test">
					<Button type="link" onClick={handleClearAllFilters}>
						Reset All
					</Button>
				</div>
			</>
		);
	}, [filterMode, selectedFilters]);

	const folderMemo = useMemo(() => {
		const dropdownItemOptions: any = (
			fileType: string,
			record: any,
			index: number
		) => {
			if (fileType == "folder") {
				return [
					{
						label: "Rename",
						key: "-1",
						onClick: () => {
							let tempRecord = { ...record };
							tempRecord.fileSuffix = "";
							setTempFileName(record.name);
							setNewFileName(record.name);
							setTempRecord(tempRecord);
							setRenameModalOpen(true);
						},
					},
					{ type: "divider" },
					{
						label: "Delete",
						key: "4",
						onClick: () => {
							let tempRecord = { ...record };
							tempRecord.index = index;
							setIsDeleteMultiple(false);
							setDeleteModalOpen(true);
							setTempRecord(tempRecord);
						},
					},
				];
			} else if (fileType == "file") {
				return [
					{
						label: "Rename",
						key: "-1",
						onClick: () => {
							let tempRecord = { ...record };
							const lastDotIndex = record.name?.lastIndexOf(".");
							const prefix = record.name.substring(0, lastDotIndex);
							const suffix = "." + record.name.substring(lastDotIndex + 1);
							tempRecord.fileSuffix = suffix;
							setTempFileName(prefix);
							setNewFileName(prefix);
							setTempRecord(tempRecord);
							setRenameModalOpen(true);
						},
					},
					{
						label: "Preview",
						key: "0",
						onClick: () => {
							getFilePreview(
								context.currentTenantKey,
								record.id,
								containerId
							).then((response: any) => {
								window.open(response?.getUrl, "__blank");
							});
						},
					},
					fileTools.checkSharepointEdittable(record.file.mimeType) && {
						label: "Edit",
						key: "1",
						onClick: () => {
							collabo(context.currentTenantKey, record.id, containerId).then(
								(response: any) => {
									console.log(response);
									window.open(response?.link.webUrl, "__blank");
								}
							);
						},
					},
					{
						label: "Download",
						key: "2",
						onClick: () => {
							if (record[`@microsoft.graph.downloadUrl`]) {
								window.open(record[`@microsoft.graph.downloadUrl`], "_self");
							}
						},
					},
					{
						type: "divider",
					},
					{
						label: "Delete",
						key: "4",
						onClick: () => {
							let tempRecord = { ...record };
							tempRecord.index = index;
							setIsDeleteMultiple(false);
							setDeleteModalOpen(true);
							setTempRecord(tempRecord);
						},
					},
				];
			} else {
				return [];
			}
		};

		const filterFileTypeOptions: any = () => {
			let filteredData = [...filteredItems];
			let arrayTypes: any = filteredData.map((record: any) => {
				if (record.folder) {
					return "File folder";
				} else if (record.file) {
					return record.file.mimeType;
				}
			});
			const uniqueArray = arrayTypes.filter(
				(item: any, index: any) => arrayTypes.indexOf(item) === index
			);
			return uniqueArray.map((element: any) => ({
				text: fileTools.getReadableFileType(element) || element,
				value: element,
			}));
		};

		const breadcrumbRenderer = () => {
			if (folderHierarchy.length > 0) {
				return (
					<>
						<a
							className="document-breadcrumb-clickable-text"
							onClick={() => {
								handleFolderChange(rootFolderId, "", -1);
							}}
						>
							Contents
						</a>
						{folderHierarchy.map((folder: any, index: number) => {
							if (index === folderHierarchy.length - 1) {
								return (
									<>
										<span className="document-breadcrumb-seperator">/</span>
										<span className="document-breadcrumb-text">
											{folder.name || "undefined"}
										</span>
									</>
								);
							} else {
								return (
									<>
										<span className="document-breadcrumb-seperator">/</span>
										<a
											className="document-breadcrumb-clickable-text"
											onClick={() => {
												handleFolderChange(folder.id, folder.name, index);
											}}
										>
											{folder.name || "undefined"}
										</a>
									</>
								);
							}
						})}
					</>
				);
			} else {
				return <span>Contents</span>;
			}
		};

		const rowSelection: TableProps<any>["rowSelection"] = {
			onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
				setRowSelectedIDs(selectedRowKeys);
				console.log(
					`selectedRowKeys: ${selectedRowKeys}`,
					"selectedRows: ",
					selectedRows
				);
			},
			getCheckboxProps: (record: any) => ({
				disabled: record.name === "Disabled User", // Column configuration not to be checked
				name: record.name,
			}),
		};

		return (
			<>
				{" "}
				<div className="document-container-header">
					<div id={"documentBreadcrumb"} className="document-breadcrumb">
						{breadcrumbRenderer()}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							marginLeft: "auto",
							gap: "10px",
						}}
					>
						<Button
							icon={GetAntIcon("delete")}
							style={rowSelectedIDs.length > 0 ? {} : { display: "none" }}
							onClick={() => {
								setDeleteModalOpen(true);
								setIsDeleteMultiple(true);
							}}
						>
							Delete
						</Button>
						<Button
							icon={GetAntIcon("folder-outline")}
							onClick={() => {
								setFolderModalOpen(true);
							}}
						>
							Create folder
						</Button>
						<Upload
							multiple
							beforeUpload={() => false}
							fileList={fileList}
							onRemove={handleRemove}
							onChange={(info: any) => {
								setUploadLength(info.fileList.length);
								setFileList((prevList: any) => [...prevList, info.file]);
							}}
							itemRender={() => <></>}
						>
							<Button
								icon={GetAntIcon("upload")}
								// onClick={() => {
								// 	setFileModalOpen(true);
								// }}
							>
								Upload file
							</Button>
						</Upload>
					</div>
				</div>
				<Table
					// rowSelection={{
					// 	type: selectionType,
					// 	...rowSelection,
					// 	hideSelectAll: true,
					// }}
					// rowKey={"id"}
					rowHoverable={false}
					footer={undefined}
					pagination={false}
					style={{ width: "100%" }}
					rootClassName="audit-log-table" //styling only
					className="documents-table"
					bordered
					dataSource={filteredItems}
					// scroll={tableHeight && { y: tableHeight, x: 0 }}
					tableLayout="auto"
					columns={[
						{
							title: (
								<SearchButton
									handleSearch={handleSearch}
									headerName="Name"
									columnKey="name"
									key={"phase"}
								/>
							),
							dataIndex: "name",
							key: "name",
							render: (value: any, record: any, index: any) => {
								let fileType: string = record.file
									? "file"
									: record.folder
									? "folder"
									: "";

								let prefix = record.name;
								const lastDotIndex = record.name?.lastIndexOf(".");

								if (lastDotIndex != -1) {
									prefix = record.name.substring(0, lastDotIndex);
								}
								let checked = rowSelectedIDs.includes(record.id);

								return (
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-between",
											alignItems: "center",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												gap: "10px",
											}}
										>
											<Checkbox
												checked={rowSelectedIDs.includes(record.id)}
												key={`${record.id}-checkbox`}
												onChange={(e: CheckboxChangeEvent) => {
													let tempIds = [...rowSelectedIDs];
													if (e.target.checked) {
														tempIds.push(record.id);
													} else {
														const foundIndex = rowSelectedIDs.findIndex(
															(id: string) => record.id === id
														);
														if (foundIndex != -1) {
															tempIds.splice(foundIndex, 1);
														}
													}
													console.log(record.id);
													console.log(tempIds);
													setRowSelectedIDs(tempIds);
												}}
											></Checkbox>
											<a
												className="table-clickable-text"
												onClick={() => {
													if (record.folder) {
														handleFolderChange(record.id, record.name);
													} else if (record.file) {
														if (
															fileTools.checkSharepointEdittable(
																record.file.mimeType
															)
														) {
															collabo(
																context.currentTenantKey,
																record.id,
																containerId
															).then((response: any) => {
																console.log(response);
																window.open(response?.link.webUrl, "__blank");
															});
														} else {
															getFilePreview(
																context.currentTenantKey,
																record.id,
																containerId
															).then((response: any) => {
																window.open(response?.getUrl, "__blank");
															});
														}
													}
												}}
											>
												<span className="table-clickable-icon-text">
													{record.folder
														? GetAntIcon("folder-outline")
														: GetAntIcon("file2")}{" "}
												</span>
												{prefix}
											</a>
										</div>

										<Dropdown
											menu={{
												items: dropdownItemOptions(fileType, record, index),
											}}
											trigger={["click"]}
										>
											<a onClick={(e) => e.preventDefault()}>
												{GetAntIcon("ellipsis")}
											</a>
										</Dropdown>
									</div>
								);
							},
						},
						{
							title: (
								<SearchButton
									handleSearch={handleSearch}
									headerName="Owner"
									columnKey="owner"
									key={"owner"}
								/>
							),
							dataIndex: "owner",
							key: "owner",
							render: (value: any, record: any) => {
								let owner = ownerList.find(
									(owner: any) => record.id === owner.item_id
								);
								return owner?.owner_email || "-";
							},
							// width: 200,
						},
						{
							title: "Date added",
							dataIndex: "createdDateTime",
							key: "creation_date",
							render: (value: any) => (
								<span>
									{value && dayjs(value)?.format("D MMM YYYY hh:mm:ss A")}
								</span>
							),
							sorter: (a: any, b: any) => {
								const stringA = a.createdDateTime || "";
								const stringB = b.createdDateTime || "";
								return stringA.localeCompare(stringB);
							},
						},
						{
							title: "Last modified",
							dataIndex: "lastModifiedDateTime",
							key: "last_modified_date",
							render: (value: any) => (
								<span>
									{value && dayjs(value)?.format("D MMM YYYY hh:mm:ss A")}
								</span>
							),
							sorter: (a: any, b: any) => {
								const stringA = a.lastModifiedDateTime || "";
								const stringB = b.lastModifiedDateTime || "";
								return stringA.localeCompare(stringB);
							},
						},
						{
							title: "Type",
							dataIndex: "type",
							key: "type",
							filters: filterFileTypeOptions(),
							onFilter: (value: any, record: any) => {
								console.log(record.file?.mimeType, value);
								return (
									record.file?.mimeType == value ||
									(record.folder && value === "File folder")
								);
							},
							render: (value: any, record: any) => {
								if (record.folder) {
									return <span>File folder</span>;
								} else if (record.file) {
									return (
										<span>
											{fileTools.getReadableFileType(record.file.mimeType)}
										</span>
									);
								}
							},
						},
						{
							title: "Size",
							dataIndex: "size",
							key: "size",
							sorter: (a: any, b: any) => a.size - b.size,
							render: (value: any) => {
								// let renderedValue = (value / 1000000).toFixed(4);
								// return <span>{value && renderedValue}</span>;

								return <span>{fileTools.formatFileSize(value)}</span>;
							},
						},
					]}
				></Table>
			</>
		);
	}, [
		currentFolderId,
		filteredItems,
		currentFolderFiles,
		folderHierarchy,
		rowSelectedIDs,
		fileList,
	]);

	const quickAccessMemo = useMemo(() => {
		return (
			<>
				{quickAccessFiles.map((file: any) => {
					let filename = quickAccessFileMetaData?.[file.item_id]?.name;
					const lastDotIndex = filename?.lastIndexOf(".");

					if (lastDotIndex != -1) {
						filename = filename?.substring(0, lastDotIndex);
					}
					return (
						<Spin spinning={!quickAccessFileMetaData[file.item_id]}>
							<div
								className={"quick-access-item"}
								style={{
									cursor: "pointer",
									width: "260px",
									height: "185px",
									padding: "20px",
									gap: "15px",
									display: "flex",
									flexDirection: "column",
								}}
								onClick={() => {
									if (
										fileTools.checkSharepointEdittable(
											quickAccessFileMetaData?.[file.item_id]?.filetype
										)
									) {
										collabo(
											context.currentTenantKey,
											file.item_id,
											containerId
										).then((response: any) => {
											console.log(response);
											window.open(response?.link.webUrl, "__blank");
										});
									} else {
										getFilePreview(
											context.currentTenantKey,
											file.item_id,
											containerId
										).then((response: any) => {
											window.open(response?.getUrl, "__blank");
										});
									}
								}}
							>
								<Image
									preview={false}
									height={80}
									width={220}
									src={
										quickAccessFileMetaData[file.item_id]?.thumbnail_url ||
										"fallback-image-url"
									}
								></Image>
								<div
									style={{
										flex: 1,
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
									}}
								>
									<span style={{ fontWeight: "700" }}>{filename}</span>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}
									>
										<span style={{ opacity: "0.6" }}>
											{ownerList.find(
												(owner: any) => file.item_id === owner.item_id
											)?.owner_email || "-"}
										</span>
										<span>
											<span style={{ opacity: "0.6" }}>
												{quickAccessFileMetaData[file.item_id]
													?.createdDateTime &&
													dayjs(
														quickAccessFileMetaData[file.item_id]
															?.createdDateTime
													)?.format("D MMM YYYY")}
											</span>
										</span>
									</div>
								</div>
							</div>
						</Spin>
					);
				})}
			</>
		);
	}, [quickAccessFiles, quickAccessFileMetaData]);

	const createFolderModal = () => {
		return (
			<Modal
				closable={false}
				width={"40%"}
				centered
				styles={{ header: { padding: "12px 16px" } }}
				open={folderModalOpen}
				title="Create new folder"
				footer={() => (
					<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
						<Button
							onClick={() => {
								setFolderName("");
								setFolderModalOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							disabled={folderName == ""}
							onClick={handleFolderCreation}
						>
							Save
						</Button>
					</div>
				)}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
					<span>Folder name</span>
					<Input
						value={folderName}
						onChange={(event: any) => {
							console.log(event.target.value);
							setFolderName(event.target.value);
						}}
						allowClear
					></Input>
				</div>
			</Modal>
		);
	};

	// const uploadFileModal = () => {
	// 	return (
	// 		<Modal
	// 			closable={false}
	// 			width={"40%"}
	// 			centered
	// 			styles={{ header: { padding: "12px 16px" } }}
	// 			open={fileModalOpen}
	// 			title="Upload new file"
	// 			footer={() => (
	// 				<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
	// 					<Button
	// 						onClick={() => {
	// 							setFileList([]);
	// 							setFileModalOpen(false);
	// 						}}
	// 					>
	// 						Cancel
	// 					</Button>
	// 					<Button
	// 						type="primary"
	// 						disabled={fileList.length == 0}
	// 						onClick={handleFileUpload}
	// 					>
	// 						Save
	// 					</Button>
	// 				</div>
	// 			)}
	// 		>
	// 			<Upload
	// 				multiple
	// 				beforeUpload={handleBeforeUpload}
	// 				fileList={fileList}
	// 				onRemove={handleRemove}
	// 			>
	// 				<Button icon={GetAntIcon("upload")}>Select Files</Button>
	// 			</Upload>
	// 		</Modal>
	// 	);
	// };

	const renameFileModal = () => {
		let filetype = tempRecord.file ? "file" : "folder";
		return (
			<Modal
				closable={false}
				width={"40%"}
				centered
				styles={{ header: { padding: "12px 16px" } }}
				open={renameModalOpen}
				title={`Rename ${filetype}`}
				footer={() => (
					<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
						<Button
							onClick={() => {
								setTempFileName("");
								setNewFileName("");
								setTempRecord({});
								setRenameModalOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							disabled={tempFileName == newFileName}
							onClick={handleFileRename}
						>
							Save
						</Button>
					</div>
				)}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
					<span>New {filetype} name</span>
					<Input
						value={newFileName}
						onChange={(event: any) => {
							setNewFileName(event.target.value);
						}}
						allowClear
					></Input>
				</div>
			</Modal>
		);
	};

	const deleteFileModal = () => {
		let recordIdList = isDeleteMultiple ? rowSelectedIDs : [tempRecord.id];

		let filetype = tempRecord.file ? "file" : "folder";

		return (
			<Modal
				closable={false}
				width={"40%"}
				centered
				styles={{ header: { padding: "12px 16px" } }}
				open={deleteModalOpen}
				title={`Delete ${isDeleteMultiple ? "file(s)" : filetype}`}
				footer={() => (
					<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
						<Button
							onClick={() => {
								setDeleteModalOpen(false);
								setTempRecord({});
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							danger
							onClick={() =>
								handleFileDelete(
									recordIdList,
									isDeleteMultiple,
									tempRecord,
									filetype,
									tempRecord?.index
								)
							}
						>
							Delete
						</Button>
					</div>
				)}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
					<span>
						Are you sure you want to permanently delete{" "}
						{isDeleteMultiple ? "these files" : `this ${filetype}`}? Once
						deleted, it cannot be restored.
					</span>
				</div>
			</Modal>
		);
	};

	const confirmUploadModal = () => {
		let recordIdList = isDeleteMultiple ? rowSelectedIDs : [tempRecord.id];

		let filetype = tempRecord.file ? "file" : "folder";

		return (
			<Modal
				closable={false}
				width={"40%"}
				centered
				styles={{ header: { padding: "12px 16px" } }}
				open={confirmUploadModalOpen}
				title={`Upload confirmation`}
				footer={() => (
					<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
						<Button
							onClick={() => {
								setFileList([]);
								setUploadLength(0);
								setConfirmUploadModalOpen(false);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							danger
							onClick={() => {
								setConfirmUploadModalOpen(false);
								handleFileUpload(fileList);
							}}
						>
							Proceed
						</Button>
					</div>
				)}
			>
				<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
					<span>
						A file with the same name has already been uploaded. Uploading it
						again will replace the existing file. Do you wish to proceed?
					</span>
				</div>
			</Modal>
		);
	};

	const loadingModal = () => {
		let filetype = tempRecord.file ? "file" : "folder";
		let modalTitle = "";
		if (actionType === "upload") {
			modalTitle = "File upload";
		} else if (actionType === "delete") {
			modalTitle = `Delete ${isDeleteMultiple ? "file(s)" : filetype}`;
		}

		return (
			<Modal
				closable={false}
				width={"40%"}
				centered
				title={modalTitle}
				styles={{
					header: { padding: "12px 30px" },
					body: {
						padding: "30px",
						minHeight: "350px",
						height: "350px",
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					},
				}}
				open={loadingModalOpen}
				footer={null}
			>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						top: 0,
						insetInlineStart: 0,
						height: "80%",
						width: "100%",
						margin: 0,
					}}
				>
					<img
						style={{ position: "absolute", height: "40%" }}
						src={elementalLoadingLogo}
					/>
					<img
						className="spin"
						style={{ position: "absolute", height: "55%" }}
						src={elementalLoadingOutline}
					/>
				</div>
				<div style={{ width: "100%" }}>
					<span>
						{actionType === "upload" ? "Uploading" : "Deleting"} files...
					</span>
					<Progress percent={loadingPercentage}></Progress>
				</div>
			</Modal>
		);
	};

	const setWellContent = (currentData: any, infoKey: any) => {
		infoKey === "well_operator" &&
			console.log("i-debug well content", currentData[infoKey]);
		let content = "-";
		if (
			currentData[infoKey] ||
			infoKey == "latitude" ||
			infoKey == "longitude"
		) {
			content = setLookupData(context.allData, currentData, infoKey);
			if (infoKey === "location") {
				content =
					currentData[infoKey].charAt(0).toUpperCase() +
						currentData[infoKey].slice(1) || "-";
			}
		}
		return content;
	};

	return (
		<>
			{!isLoading && (
				<div
					className="generic-container"
					style={{ gap: "20px", overflow: "auto" }}
				>
					{/* Top Container */}
					<div
						className="well-details-top-content main-container"
						id="main-container"
					>
						<div
							className="container-header"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<div
								className="panel-info"
								onClick={() => {
									setWellDetailsExpand(!wellDetailsExpand);
								}}
							>
								<span>Well details</span>
								{wellDetailsExpand ? GetAntIcon("up") : GetAntIcon("downarrow")}
							</div>
							{/* Panel Buttons Container */}
							<div className="panel-main-buttons-container">
								<a
									style={{ marginTop: "auto" }}
									onClick={() => {
										setOpenWellModal(true);
									}}
								>
									{GetAntIcon("setting")} Well settings
								</a>
							</div>
						</div>
						{/* Expanded Task Details */}
						{wellDetailsExpand ? (
							<div className="project-details-container">
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										maxWidth: "30%",
										height: "100%",
										position: "relative",
										gap: "20px",
									}}
								>
									{/* Panel info */}
									<div
										className="meta-data-container"
										style={{
											gridTemplateColumns: "repeat(6, auto)",
										}}
									>
										<div className="meta-data-item">
											<span className="panel-detail-label">Well name</span>
											<span className="panel-item-content">
												{wellInfo?.well_name}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Well operator</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "well_operator") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Location</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "location") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Water depth</span>
											<span className="panel-item-content">
												{wellInfo?.water_depth || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Purpose</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "well_purpose") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Status</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "well_status") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">License</span>
											<span className="panel-item-content">
												{wellInfo?.license || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Latitude</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "latitude") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Longitude</span>
											<span className="panel-item-content">
												{setWellContent(wellInfo, "longitude") || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Block</span>
											<span className="panel-item-content">
												{wellInfo?.block || "-"}
											</span>
										</div>
										<div className="meta-data-item">
											<span className="panel-detail-label">Field</span>
											<span className="panel-item-content">
												{wellInfo?.field || "-"}
											</span>
										</div>
									</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
					{/* Bottom Container */}
					<div
						className="well-details-bottom-content main-container"
						style={{
							minHeight: "85%",
							flex: 1,
							width: "100%",
							// maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div className="project-header">
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "operations" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("operations");
									}}
								>
									{GetAntIcon("product")}
									<span>Operations</span>
								</div>{" "}
								<div
									className={
										activePage === "documents" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("documents");
									}}
								>
									{GetAntIcon("file2")}
									<span>Documents</span>
								</div>
							</div>
							{/* <div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search by task name"
									className="input-clearable"
									style={{
										maxWidth: "265px",
										display: activePage === "documents" ? "none" : "",
									}}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
								</div>
							</div> */}
						</div>
						<div
							className="generic-content project-overview-container"
							style={{
								flex: 1,
								display: "flex",
								flexWrap: "inherit",
								flexDirection: "column",
								alignItems: "flex-start",
								padding: 0,
								gap: 0,
								overflowX: "auto",
								maxHeight: "100%",
							}}
						>
							{/* <div
								style={{
									display: "flex",
									padding: filterMode ? "12px 30px" : "0 30px",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "5px",
									transform: filterMode ? "translateY(0)" : "translateY(-150%)",
									alignSelf: "stretch",
									background: "rgba(6,45,48)",
									transition: "0.3s ease-in-out",
								}}
							>
								{filterMode && (
									<>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												gap: "10px",
												padding: "8px",
												marginRight: "20px",
												alignItems: "center",
												height: "40px",
											}}
										>
											Hide excluded items
											<Switch
												// checkedChildren="Hide excluded items"
												// unCheckedChildren="Show excluded items"
												defaultValue={hideExcluded}
												// style={{ padding: "8px" }}
												onChange={(checked: any) => {
													setHideExcluded(checked);
												}}
											/>
										</div>
										{activePage === "kanban" && (
											<>
												<CheckboxDropdown
													options={projectRoleAssignmentList}
													onChange={(selectedValues) =>
														handleMenuClick("assigned_to", selectedValues)
													}
													onClear={""}
													defaultValue={selectedFilters.assigned_to}
													Key="assigned_to"
													label="Assigned to"
												/>
												<CheckboxDropdown
													options={[
														{ label: "New", value: "New" },
														{
															label: "To do",
															value: "To do",
														},
														{ label: "In Progress", value: "In progress" },
														{ label: "Late", value: "Late" },
														{
															label: "Resolved",
															value: "Resolved",
														},
													]}
													onChange={(selectedValues) =>
														handleMenuClick("status", selectedValues)
													}
													onClear={""}
													defaultValue={selectedFilters.status}
													Key="status"
													label="Status"
												/>
												<div className="test">
													<Button
														type="link"
														style={{ color: "rgba(255, 255, 255, 0.5)" }}
														onClick={handleClearAllFilters}
													>
														Reset All
													</Button>
												</div>
											</>
										)}
									</>
								)}
							</div> */}
							{activePage === "operations" && (
								<div
									style={{
										display: "flex",
										flex: 1,
										alignItems: "flex-start",
										padding: "40px 30px",
										gap: "25px",
										alignSelf: "stretch",
										overflow: "auto",
									}}
								>
									{wellOperationList?.length > 0 ? (
										<div
											style={{
												display: "grid",
												gridTemplateColumns: "repeat(4, minmax(22%, 1fr))",
												width: "100%",
											}}
										>
											{wellOperationList.map((operation: any) => {
												return (
													<div
														className="project-card"
														style={{
															display: "flex",
															justifyContent: "space-between",
														}}
													>
														<div>
															<div className="panel-detail-label">
																Operation name
															</div>
															<div
																className="panel-item-content project-title-clickable"
																onClick={() => {
																	navigate(
																		`/projects/${operation?.project_code}/${operation.operation_code}`
																	);
																}}
																onContextMenu={(e: any) => {
																	const link = `${window.location.origin}/projects/${operation?.project_code}/${operation.operation_code}`;
																	handleContextMenu(e, { link: link });
																}}
															>
																{operation.operation_name}
															</div>
														</div>
														<Tag
															className="operations-tag"
															style={{ display: "flex", alignItems: "center" }}
														>
															{setWellContent(operation, "operation_type")}
														</Tag>
													</div>
												);
											})}
										</div>
									) : (
										<Empty
											className="no-data-empty"
											style={{ margin: "auto", fontSize: "20px" }}
											// image={elementalSvg}
											description="There are no operations in this well"
										/>
									)}
								</div>
							)}
							{activePage === "documents" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										flex: 1,
										width: "100%",
										padding: "40px 30px",
									}}
								>
									<div
										id="quick-access-container"
										style={{
											display: "flex",
											flexDirection: "column",
											gap: "20px",
										}}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "row",
												alignItems: "center",
												gap: "6px",
											}}
										>
											{" "}
											<span style={{ fontSize: "16px" }}>Quick Access</span>
											<Tooltip title="Showing recently added files">
												<span style={{ opacity: "0.5" }}>
													{GetAntIcon("question3")}
												</span>
											</Tooltip>
											<span
												onClick={() => {
													setQuickAccessCollapsed(!quickAccessCollapsed);
												}}
											>
												{quickAccessCollapsed
													? GetAntIcon("downarrow")
													: GetAntIcon("up")}
											</span>
										</div>

										<div
											className={`quick-access-content ${
												quickAccessCollapsed ? "collapsed" : "expanded"
											} ${
												quickAccessFiles.length > 0 ? "quick-access-margin" : ""
											}`}
											style={{
												display: "flex",
												overflowX: "scroll",
												gap: "15px",
											}}
										>
											{quickAccessMemo}
										</div>
									</div>
									<div
										id="documentContainer"
										style={{
											gap: "20px",
											display: "flex",
											flexDirection: "column",
										}}
									>
										{folderMemo}
									</div>
								</div>
							)}
						</div>
					</div>
					{/* <AuditLogModal
						tenantKey={context?.currentTenantKey}
						identifierKeys={{
							project_code: projectInfo?.project_code,
							operation_code: wellInfo?.operation_code,
						}}
						type={"well_project"}
						label={"Well project"}
						open={auditOpen}
						handleClose={() => {
							setAuditOpen(false);
						}}
					/> */}

					{createFolderModal()}
					{confirmUploadModal()}
					{renameFileModal()}

					{openWellModal && (
						<WellConfigurationModal
							open={openWellModal}
							setOpen={setOpenWellModal}
							activeRowKey={wellId}
							wellRecord={wellInfo}
							setWellRecord={setWellInfo}
							extraData={context.allData}
							user={props.params?.user}
							userRole={props.params?.userRole}
							redirectOnDelete={() => {
								navigate("/wells");
							}}
						/>
					)}
					{deleteFileModal()}
					{loadingModal()}
				</div>
			)}
			{/* {!wellInfo && !isLoading && (
				<>
					<NoAccess text={"This well does not exist."} />
				</>
			)} */}
		</>
	);
};

export default WellDetails;
