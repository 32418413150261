import { useNavigate } from "react-router";
import useHandleContextMenu from "../../hooks/useHandleContextMenu";
import { checkPrivilege } from "../../utils/utils";
import WellCards from "./WellCards";
import { countryList } from "../../utils/countryCodes";
import { useEffect, useState } from "react";
import Emitter from "../../utils/emitter";

function WellGallery(props: any) {
	const navigate = useNavigate();
	const { handleContextMenu } = useHandleContextMenu();

	const [allItems, setAllItems] = useState<any[]>(props.allItems);

	useEffect(() => {
		setAllItems(props.allItems);
	}, [props.allItems]);

	const renderAllItems = () => {
		return (
			<>
				{allItems?.length > 0 &&
					allItems.map((item: any) => {
						return (
							<WellCards
								key={item.uuid || item.id || item._id}
								item={item}
								activeItems={props.activeItems}
								setActiveItems={props.setActiveItems}
							/>
						);
					})}
			</>
		);
	};

	return (
		<div className="gallery">
			<div className="gallery-container">
				{checkPrivilege(props.userRole, props.addItemPrivilege) && (
					<div
						className="add-new-item-div project-card project-add-button"
						onClick={() => {
							Emitter.emit("wellModalOpen", null);
						}}
					>
						+ Add new well
					</div>
				)}
				{renderAllItems()}
			</div>
		</div>
	);
}

export default WellGallery;
