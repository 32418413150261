import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { HomeContext } from "./Home";
import { GetAntIcon } from "../utils/ant_icons";
import { Button, Empty, Input, Modal, Popconfirm, Switch, Tooltip } from "antd";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { getTasks, updateTaskItem } from "../services/api-server/tasks";
import { getPhaseItem } from "../services/api-server/phases";
import { getProject } from "../services/api-server/projects";
// import useCountProgress from "../hooks/useCountProgress";
import { KanbanBoardDnD } from "../components/KanbanBoardDnD";
import { getDeliverablesGeneral } from "../services/api-server/deliverables";
import CustomForm from "../components/CustomForm";
import { useForm } from "antd/es/form/Form";
import FormButtonSave from "../components/FormButtonSave";
import Emitter from "../utils/emitter";
import AuditLogModal from "../components/Modal/AuditLogModal";
import elementalSvg from "../assets/image/elemental 'e'.svg";
import { getOperations } from "../services/api-server/operations";

const PhaseOverview = (props: any) => {
	// Make sure to set currentPhase in context so that the phase name can be displayed in the breadcrumb
	const [projectRoleAssignmentList, setProjectRoleAssignmentList] =
		useState<any>([]);

	const location = useLocation();
	const context: any = useContext(HomeContext);
	const [phaseData, setPhaseData] = useState<any>(null);
	const [deliverableList, setDeliverableList] = useState<any>(null);
	const [filteredDeliverableList, setFilteredDeliverableList] =
		useState<any>(null);
	const [taskList, setTaskList] = useState<any>(null);
	const [filteredTaskList, setFilteredTaskList] = useState<any>(null);
	const [activePage, setActivePage] = useState<any>("kanban");
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<any>("");
	const [selectedFilters, setSelectedFilters] = useState<any>({
		assigned_to: [],
	});
	const [projectTeamMembersList, setProjectTeamMembersList] = useState<any>([]);
	const [blockedModalOpen, setBlockedModalOpen] = useState<boolean>(false);
	const [formReset, setFormReset] = useState<boolean>(true);
	const [cancelPopover, setCancelPopover] = useState<any>(false);
	const [activeKey, setActiveKey] = useState<any>(null);
	const [projectCode, setProjectCode] = useState<any>(null);
	const [operationCode, setOperationCode] = useState<any>(null);
	const [auditOpen, setAuditOpen] = useState<any>(false);

	const [hideExcluded, setHideExcluded] = useState<any>(true);

	// Hook to count progress of deliverables in a task
	// const { countDeliverablesProgress } = useCountProgress(
	// 	context.currentTenantKey
	// );

	const [formRef] = useForm();

	const loadData = (tenantKey: any) => {
		let project_code: any =
			location?.state?.project_code || location?.pathname?.split("/")[2];
		let operation_code: any =
			location?.state?.operation_code || location?.pathname?.split("/")[3];
		let phase_id: any =
			location?.state?.phase_id || location?.pathname?.split("/")[4];

		setProjectCode(project_code);
		setOperationCode(operation_code);

		Emitter.emit("loading", true);

		// Fetch data from API
		getProject(tenantKey, project_code)
			.then((project: any) => {
				context.setCurrentProject(project);

				let projectTeamMembers: any = [];
				let memberSet = new Set();

				project?.role_assignments?.forEach((item: any) => {
					item?.appointee?.forEach((member: any) => {
						if (!memberSet.has(member)) {
							projectTeamMembers.push({
								label: member,
								value: member,
							});
							memberSet.add(member);
						}
					});
				});

				let role1 = [{ label: "Project manager", value: "project_manager" }];
				let role2 = project.role_assignments.map((role: any) => {
					return { label: role.role, value: role.role_id };
				});
				let roles = role1.concat(role2);
				setProjectRoleAssignmentList(roles);

				setProjectTeamMembersList(projectTeamMembers);

				getOperations(tenantKey, {
					project_code,
					operation_code,
				}).then((well_project: any) => {
					context.setCurrentOperation(well_project[0]);
					getPhaseItem(tenantKey, phase_id)
						.then((phase: any) => {
							setPhaseData(phase[0]);
							context.setCurrentPhase(phase[0]);
							getTasks(tenantKey, phase_id).then((tasks: any) => {
								if (tasks) {
									getDeliverablesGeneral(tenantKey, tasks)
										.then((deliverableList: any) => {
											deliverableList?.forEach((deliverable: any) => {
												let responsibleRoles =
													deliverable.responsible_roles || [];
												let accountable_roles =
													deliverable.accountable_roles || [];
												let consulted = deliverable.consulted || [];
												let informed_parties =
													deliverable.informed_parties || [];
												let foundTaskIndex = tasks.findIndex(
													(task: any) => task._id === deliverable.task_id
												);
												if (foundTaskIndex != -1) {
													if (!tasks[foundTaskIndex].assigned_to) {
														tasks[foundTaskIndex].assigned_to = [];
													}
													tasks[foundTaskIndex].assigned_to = tasks[
														foundTaskIndex
													].assigned_to
														.concat(responsibleRoles)
														.concat(accountable_roles)
														.concat(consulted)
														.concat(informed_parties)
														.filter(
															(item: any, index: any, arr: any) =>
																arr.indexOf(item) === index
														);
												}
											});
											setTaskList(tasks);
											filterToggleData(tasks, setFilteredTaskList);
											setDeliverableList(deliverableList);
											filterToggleData(
												deliverableList,
												setFilteredDeliverableList
											);
											if (localStorage.getItem("selectedFilters")) {
												let selectedFiltersStore: any =
													localStorage.getItem("selectedFilters");
												let selectedFiltersStoreJson =
													JSON.parse(selectedFiltersStore);
												setSelectedFilters(selectedFiltersStoreJson);
												setFilterMode(true);
											}
										})
										.catch((error: any) => {
											console.log(error);
										});
								}
							});
						})
						.catch((e: any) => {
							console.log(e);
						});
				});
			})
			.catch((e: any) => {
				console.log(e);
			})
			.finally(() => {
				Emitter.emit("loading", false);
			});
	};

	const filterToggleData = (
		data: any,
		setFilteredData: (data: any) => void
	) => {
		if (hideExcluded) {
			const filteredData = data?.filter((task: any) => !task.disabled);
			setFilteredData(filteredData);
		} else {
			setFilteredData(data);
		}
	};

	useEffect(() => {
		filterToggleData(taskList, setFilteredTaskList);
		filterToggleData(deliverableList, setFilteredDeliverableList);
	}, [hideExcluded]);

	useEffect(() => {
		if (context.currentTenantKey && context.allData) {
			loadData(context.currentTenantKey);
		}
	}, [context.currentTenantKey, context.allData]);

	const handleFormSave = (values: any) => {
		// console.log("i-debug handle form save", values);
		const initialData = taskList.find((task: any) => task._id === activeKey);
		values.status = "Late";
		updateTaskCardStatus(activeKey, values, initialData);
		setBlockedModalOpen(false);
		resetForm();
		context.handleFormUpdating(false);
		// setIsSaved(true);
		// setIsCanceled(false);
	};

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			// setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		setBlockedModalOpen(false);
		setCancelPopover(false);
		// handleModalClose();
		resetForm();
	};

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	const handleMenuClick = (field: any, selectedValues: any[]) => {
		// console.log(`Selected: ${field} - ${selectedValues}`);
		// Update the selected filters state
		setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

		localStorage.setItem(
			"selectedFilters",
			JSON.stringify({ ...selectedFilters, [field]: selectedValues })
		);
	};

	const handleClearAllFilters = () => {
		// Reset all filters to empty arrays
		setSelectedFilters({
			assigned_to: [],
		});
		setFilterMode(false);

		// Clear session storage for filter values and checkboxes
		localStorage.removeItem("selectedFilters");

		setTimeout(() => {
			setFilterMode(true);
		}, 5);
	};

	const updateTaskCardStatus = (
		task_id: any,
		task_data: any,
		initialData: any
	) => {
		if (task_data.status !== initialData.status) {
			// Create a form list based on the property of task_data and initialData
			const formList: any = [];
			for (const key in task_data) {
				formList.push(key);
			}

			updateTaskItem(
				context.currentTenantKey,
				task_id,
				{
					...task_data,
					project_code: projectCode,
					operation_code: operationCode,
				},
				initialData,
				props.params.user,
				formList,
				{
					level: "phase",
					level_id: phaseData?._id,
					event_type: "update",
					event: `${task_data.task_name} state updated based on drag and drop activity`,
					details: [
						{
							// event: `${result.task_name} status updated`,
							new_data: task_data.status,
							old_data: initialData?.status,
						},
					],
					ref_tags: {
						operation_code: task_data.operation_code,
						phase_id: task_data.phase_id,
					},
					created_on: new Date(),
					created_by: props.params.user.email,
				}
			)
				.then((updatedTaskData: any) => {
					// setTaskInfo(updatedTaskData);
					// update the task list
					let updatedTaskList = taskList.map((task: any) => {
						if (task._id === task_id) {
							return updatedTaskData;
						} else {
							return task;
						}
					});
					setTaskList(updatedTaskList);
					filterToggleData(updatedTaskList, setFilteredTaskList);
					Emitter.emit("alert", {
						type: "success",
						message: `Task has been successfully updated.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.catch((e: any) => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				});
		}
		// else {
		// 	console.log("SORTING BY SEQUENCE ID");
		// 	// sort by sequence_id if sequence_id is available
		// 	let sortedTaskList = taskList.sort((a: any, b: any) => {
		// 		if ("sequence_id" in a && "sequence_id" in b) {
		// 			if (a.sequence_id && b.sequence_id) {
		// 				return a.sequence_id - b.sequence_id;
		// 			}
		// 			return 0;
		// 		}
		// 		return 0;
		// 	});
		// 	console.log("i-debug sorted task list", sortedTaskList);
		// 	setTaskList(sortedTaskList);
		// }
	};

	return (
		<>
			<div
				className="generic-container"
				style={{ gap: "20px", overflow: "auto" }}
			>
				<div
					className="main-container"
					style={{
						flex: 1,
						width: "100%",
						maxHeight: "100%",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<div className="project-header">
						<div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
							<div
								className={
									activePage === "kanban" ? "project-active-button" : ""
								}
								style={{
									display: "flex",
									padding: "4px 15px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setActivePage("kanban");
								}}
							>
								{GetAntIcon("project")}
								<span>Kanban</span>
							</div>
							<a
								style={{
									display: "flex",
									padding: "4px 10px",
									gap: "8px",
									cursor: "pointer",
								}}
								onClick={() => {
									setAuditOpen(true);
								}}
							>
								{GetAntIcon("history")} Audit log
							</a>
						</div>
						<div
							style={{
								display: "flex",
								flex: "1",
								justifyContent: "flex-end",
								alignItems: "center",
								gap: "15px",
							}}
						>
							<Input
								placeholder="Search by task name"
								className="input-clearable"
								style={{ maxWidth: "265px" }}
								onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
									setSearchValue(e.currentTarget.value);
									e.currentTarget.blur();
								}}
								onChange={(e) => {
									if (e.target.value === "") {
										setSearchValue("");
									}
								}}
								allowClear
								prefix={<SearchOutlined />}
							/>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<div
									className={
										filterMode || hasSelectedFilters()
											? "project-active-button"
											: ""
									}
									style={{ padding: "4px 10px", cursor: "pointer" }}
									onClick={() => {
										setFilterMode(!filterMode);
									}}
								>
									{GetAntIcon("filter")}
								</div>
							</div>
						</div>
					</div>
					<div
						className="generic-content project-overview-container"
						style={{
							flex: 1,
							display: "flex",
							flexWrap: "inherit",
							flexDirection: "column",
							alignItems: "flex-start",
							padding: 0,
							gap: 0,
							overflowX: "auto",
							maxHeight: "100%",
						}}
					>
						<div
							className={`filter-area ${
								filterMode ? "show-filter" : "hide-filter"
							}`}
						>
							{filterMode && (
								<>
									{" "}
									{/* <CheckboxDropdown
									options={projectTeamMembersList}
									onChange={(selectedValues) =>
										handleMenuClick("assigned_to", selectedValues)
									}
									onClear={""}
									defaultValue={selectedFilters.assigned_to}
									Key="assigned_to"
									label="Assigned to"
								/> */}
									Hide excluded items
									<Switch
										defaultValue={hideExcluded}
										onChange={(checked: any) => {
											setHideExcluded(checked);
										}}
									/>
									<CheckboxDropdown
										options={projectRoleAssignmentList}
										onChange={(selectedValues) =>
											handleMenuClick("assigned_to", selectedValues)
										}
										onClear={""}
										defaultValue={selectedFilters.assigned_to}
										Key="assigned_to"
										label="Assigned to"
									/>
									{/* <CheckboxDropdown
									options={[
										{ label: "New", value: "New" },
										{
											label: "To do",
											value: "To do",
										},
										{ label: "In Progress", value: "In progress" },
										{ label: "Blocked", value: "Blocked" },
										{
											label: "Completed",
											value: "Completed",
										},
									]}
									onChange={(selectedValues) =>
										handleMenuClick("status", selectedValues)
									}
									onClear={""}
									defaultValue={selectedFilters.status}
									Key="status"
									label="Status"
								/> */}
									<div className="test">
										<Button
											type="link"
											// style={{ color: "rgba(255, 255, 255, 0.5)" }}
											onClick={handleClearAllFilters}
										>
											Reset All
										</Button>
									</div>
								</>
							)}
						</div>
						{
							activePage === "kanban" && taskList && taskList?.length > 0 ? (
								<KanbanBoardDnD
									// Plan was to put taskList inside the columnList props so that I dont have to specify the field name in the KanbanBoardDnD but since need to hurry i just pass each of them as a props
									columnList={[
										{
											columnName: "Backlog",
											status: "New",
										},
										{
											columnName: "To do",
											status: "To do",
										},
										{
											columnName: "In progress",
											status: "In progress",
										},
										{
											columnName: "Late",
											status: "Late",
										},
										{
											columnName: "Resolved",
											status: "Resolved",
										},
										{
											columnName: "Closed",
											status: "Closed",
											droppable: false,
										},
									]}
									cardData={filteredTaskList}
									allExpandList={filteredDeliverableList}
									// cardHeader={["task_number", "task_name"]}
									cardHeader={["task_name"]}
									// progressCount={countDeliverablesProgress}
									searchValue={searchValue}
									selectedFilters={selectedFilters}
									updateCardStatus={updateTaskCardStatus}
									setBlockedModalOpen={setBlockedModalOpen}
									setActiveKey={setActiveKey}
								/>
							) : (
								<Empty
									className="no-data-empty"
									style={{ margin: "auto", fontSize: "20px" }}
									image={elementalSvg}
									description="Tasks are not added yet."
								/>
							)
							// {taskList.length > 0 ? (<div>Not empty</div>) : <Empty />}
						}
					</div>
				</div>
				<AuditLogModal
					tenantKey={context?.currentTenantKey}
					identifierKeys={{
						project_code: projectCode,
						operation_code: operationCode,
						phase_id: phaseData?._id,
					}}
					type={"phase"}
					label={"Phase"}
					open={auditOpen}
					handleClose={() => {
						setAuditOpen(false);
					}}
				/>
			</div>

			{blockedModalOpen && (
				<Modal
					closable={false}
					width={"40%"}
					title={"Reason for 'Late' task"}
					open={blockedModalOpen}
					centered
					destroyOnClose
					footer={() => {
						return (
							<div className="generic-footer">
								<Popconfirm
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											<>
												You have unsaved changes. Discard them or continue
												editing?
											</>
										</div>
									}
									open={cancelPopover}
									placement="topRight"
									cancelButtonProps={{ ghost: true }}
									cancelText="Discard changes"
									onCancel={() => {
										setCancelPopover(false);
										// handleFormCancel();
										setTimeout(() => {
											handleFormCancel();
										}, 300);
									}}
									okText="Continue editing"
									okType="default"
									onConfirm={() => {
										setCancelPopover(false);
									}}
								>
									<Button
										className="ant-btn-secondary"
										onClick={() => {
											if (formReset) {
												handleFormCancel();
											} else {
												setCancelPopover(true);
											}
										}}
									>
										Cancel
									</Button>
								</Popconfirm>
								<Popconfirm
									icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											<>
												You have unsaved changes. Discard them or continue
												editing?
											</>
										</div>
									}
									placement="topRight"
								>
									<FormButtonSave
										form={formRef}
										handleFormSave={handleFormSave}
										formReset={formReset}
										setFormReset={setFormReset}
									></FormButtonSave>
								</Popconfirm>
							</div>
						);
					}}
				>
					<div
						style={{
							// height: "30vh",
							display: "flex",
							flexDirection: "row",
							// gap: "30px",
							// padding: "15px",
							flexWrap: "wrap",
						}}
					>
						<CustomForm
							setFormReset={setFormReset}
							formRef={formRef}
							tabKey={"task-blocker"}
							// activeRowKey={activeRowKey}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};

export default PhaseOverview;
