import { Button, Modal, Popconfirm } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomForm from "../CustomForm";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import { HomeContext } from "../../containers/Home";
import { getCrudaClass } from "../../utils/lookup_list";
import Emitter from "../../utils/emitter";
import { getWells } from "../../services/api-server/wells";
import { getOperations } from "../../services/api-server/operations";
import { checkPrivilege } from "../../utils/utils";

function OperationConfigModal(props: any) {
	const context: any = useContext(HomeContext);

	// form related
	const [formRef] = useForm();
	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);

	const [operationInfo, setOperationInfo] = useState<any>(null);

	useEffect(() => {
		if (props.operationRecord) {
			resetForm(props.operationRecord);
			setActiveRowKey(props.operationRecord._id);
			setOperationInfo(props.operationRecord);
		}
	}, [props.operationRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		// Ensure formRef exists
		if (formRef) {
			setFormReset(true); // `setFormReset` to `true`
			reset && formRef.resetFields(); // If 'reset' is true, reset the
			formRef.setFieldsValue(values ? { ...values } : null); // Set new values or clear fields
			setInitialForm(values); // Update initial form values
		}
	};

	const handleFormCancel = () => {
		props.setOpen(false);
		setCancelPopover(false);
		context?.handleFormUpdating(false, props.activeRowKey);
		if (operationInfo) {
			resetForm(operationInfo);
			setOperationInfo(operationInfo);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("operation").then((cruda: any) => {
			console.log("activeRowKey of operation", activeRowKey);
			// Update Operation Item
			if (activeRowKey) {
				console.log("updating data", activeRowKey);
				const form = cruda.getFormList("operation");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				cruda
					.updateLookupItem(
						"operation",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						values,
						initialForm,
						{},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						resetForm(values, true);
						context.setCurrentWell(updatedItem);
						// Emitter.emit("forceUpdate", null);
						
						const newOperationData = 
						// Iterate through the 'operation_data' array to find the operation item with the matching '_id'
						operationInfo?.operation_data?.map(
							(item: any) => {
								// Check if the current item has the same '_id' as the updating item
								if (item._id === updatedItem._id) {
									// If they match, return the updated item to replace the old one
									return updatedItem;
								}
								// If they don't match, return the original item (no change)
								return item;
							}
						);

						const newRecord = {
							...operationInfo,
							operation_data: newOperationData,
						};

						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} else {
			// Add Operation Item
				console.log("adding data", props);
				
				const updatedOperationData = operationInfo?.operation_data?.filter(
					// Add all items into the `updatedOperationData` array whose `._id` does not match `activeRowKey`
					(item: any) => item._id !== activeRowKey
				) || []; // Fallback to empty array if no operation data exists
				
				cruda
					.addLookupItem(
						"operation",
						props?.option,
						values,
						{
							project_code: context?.currentProject?.project_code,
							well_id: operationInfo?.well_id,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");
						resetForm(values, true);
						// Set the active row key to a unique identifier for the added item
						setActiveRowKey(
							// Priority check for different identifiers in addedItem
							addedItem?.uuid ||       // First, try `uuid` if available
							addedItem?.group_uuid || // If not, try `group_uuid`
							addedItem?.tag_uuid ||   // If not, try `tag_uuid`
							addedItem?._id           // If none of the above, fall back to `_id`
						);
						// Emitter.emit("forceUpdate", null);

						// Clone `operationInfo` object to create a new object named `newRecord`
						const newRecord = { ...operationInfo };
						// Fetch `operation_data` array within `newRecord` object 
						newRecord.operation_data = [
							// `...` spread operator is used to extract the items from `updatedOperationData` array into `newRecord.operation_data` array while appending the newly added operation item
							...updatedOperationData, addedItem
						];

						/*
						// Retrieve `operation_data` array in the `newRecord` object if it exists or intialize it and then store in `operationList` variable
						const operationList = newRecord?.operation_data || [];
						// Add `addedItem` object (Newly added operation item) into `operationList` object
						operationList.push(addedItem);
						// Overwrite `operation_data` array into `newRecord` object with `operationList` variable
						newRecord.operation_data = operationList;
						// Set `newRecord` object's id to be the same as the `addedItem._id`
						newRecord._id = addedItem._id;
						*/

						// Emit event to reset form with `newRecord` data
						Emitter.emit("resetForm", newRecord);
						// Emit event to trigger UI update with new `operation_data`
						Emitter.emit("forceUpdate", {
							data: { operation_data: newRecord.operation_data },
						});
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (values: any) => {
		// Signals that the form is performing a delete action on the item identified by `activeRowKey` while setting the updating state to `false`
		context?.handleFormUpdating(false, activeRowKey);
		// Console log the deleting operation item
		console.log("Deleting Operation Item: ", activeRowKey);
		
		// Retrieves a CRUD handler for operations
		getCrudaClass("operation")
			// If the handler is available, pass the `cruda` object into the callback function
			.then((cruda: any) => {
			
			cruda
				// Calls custom method `deleteLookupItem` with details to delete the specific item
				.deleteLookupItem(
					"operation", // The lookup type (type of data to delete)
					props?.option, // Property inside `props`
					activeRowKey, // UID for the row or record to be deleted
					context?.currentTenantKey, // Check for `currentTenantKey`
					{ project_code: context?.currentProject?.project_code },
					props.user // User performing the deletion
				)
				// If the `Promise` (operation) is successful
				.then(() => {
					// Shows a success alert with a message
					Emitter.emit("alert", {
						type: "success", // Type of alert ('success')
						message: `Operation has been successfully deleted.`, // The message to show in the alert
						description: "", // Additional description (empty in this case)
						top: true, // Position of the alert (top of the screen)
						closeable: false, // Prevents the alert from being closed manually
						timeout: 3000, // Alert will disappear after 3 seconds
					});

					// Closes the modal
					props.setOpen(false);

					// Closes the delete confirmation popover
					setDeletePopover(false);

					// Resets the active row key, removing selection
					setActiveRowKey(null);

					const newOperationData = 
					// Checks the `operation_data` array, which may or may not contain the data 
					operationInfo?.operation_data
					// `fitler()` creates a new array by filtering out the items that do not match the condition below
					?.filter(
						// Check for any `item._id` that does not match the `activeRowKey` and keep them in the new array	
						(item: any) => item._id !== activeRowKey
					);
					
					// Update the `operationInfo` with the new data after deletion
					const newRecord = {
						...operationInfo,
						operation_data: newOperationData,
					};

					console.log("Updated Operation Data After Deletion:", newRecord);

					// Emit events to update the form and the data in the UI
					Emitter.emit("resetForm", newRecord);
					Emitter.emit("forceUpdate", {
						data: { operation_data: newOperationData },
					});

					// Reset the form since the operation was deleted
					resetForm(null, true);

					// If a callback function `redirectOnDelete` is provided in the props, it will redirect the user
					if (props.redirectOnDelete) {
						props.redirectOnDelete();
					}
				})
				// If the `Promise` fails (an error occurs)
				.catch(() => {
					// Shows an error alert if something goes wrong
					Emitter.emit("alert", {
						type: "error", // The type of the alert, in this case, 'error'
						message: "Changes are not saved. Please try again.", // The message to show in the alert
						description: "", // Additional description (empty in this case)
						top: true, // Position of the alert (top of the screen)
						closeable: false, // Prevents the alert from being closed manually
						timeout: 3000, // Alert will disappear after 3 seconds
					});
				})
				// Finally block (runs regardless of success or failure)
				.finally(() => {
					// Emit a "loading" event to hide the loading indicator (set it to false)
					// Emitter.emit("loading", false);
				});
		});
	};

	return (
		<>
			{/* Well operation configuration - Popup Modal */}
			<Modal
				// Removes the close button in the top-right corner of the modal 
				closable={false}
				// Adds a title to the modal
				title={"Well - operation configuration"}
				// Controls the visibility of the modal using `open` prop, visible if `true`, invisible if `false`
				open={props.open}
				// Centers the modal vertically and horizontally
				centered
				// Ensure the modal's content is removed from the DOM when it's closed
				destroyOnClose
				// Defines custom footer content inside the modal
				footer={() => {
					return (
						<>
							{(checkPrivilege(props?.userRole, ["tenant-admin"]) ||
								props?.user?.email == props?.currentProjectData?.project_manager) && (
									// Delete - Popconfirm
									<Popconfirm
										placement="topLeft"
										title={
											<div style={{ maxWidth: "300px", padding: "10px" }}>
												<>
													Permanently delete this well operation?
													<br />
													This action cannot be undone.
												</>
											</div>
										}
										open={deletePopover}
										onConfirm={handleFormDelete}
										overlayClassName="popconfirm-danger"
										onCancel={() => {
											setDeletePopover(false);
										}}
										okText="Delete"
										okType="danger"
										icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
										cancelText={<span>Cancel</span>}
										cancelButtonProps={{ ghost: true }}
									> 
										{/* Delete Button */}
										<Button
											disabled={!activeRowKey}
											className="ant-btn-secondary"
											style={{ marginRight: "auto" }}
											danger
											onClick={() => {
												setDeletePopover(true);
											}}
										>
											Delete
										</Button>
									</Popconfirm>
								)
							}
							{/* Cancel Button - Popconfirm */}
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								{/* Cancel Button */}
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>
							{/* Save Button */}
							<FormButtonSave
								// links the save button to the specific item currently being edited, so it knows exactly which item to update
								activeKey={activeRowKey}
								// passes a reference to the form `formRef`, allowing `FormButtonSave` to directly access the form data or controls for saving
								form={formRef}
								// triggers `handleFormSave` function when button is clicked
								handleFormSave={handleFormSave}
								// links the `formReset` state to this button
								formReset={formReset}
								setFormReset={setFormReset}
							/>
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"operation"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={props.extraData}
						activeRowKey={activeRowKey}
						activeRecord={props.operationRecord}
					/>
				</div>
			</Modal>
		</>
	);
}

export default OperationConfigModal;
