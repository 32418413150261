import axios from "axios";
import { url, AuthHeader } from "../../utils/_exports";
const apiURL = "phases";

export const getPhasesAndTasks = (tenantKey: any, operation_code: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}/getPhasesTasks`, {
				params: {
					filter: {
						...operation_code,
					},
					option: {},
					sort: { sequence_id: 1 },
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				if (_data?.data) {
					resolve(_data.data);
				} else {
					resolve(null);
				}
			})
			.catch((e: any) => {
				console.log(e);
				reject(e);
			});
	});
};

/**
 * 
 * @param tenantKey tenant key to select the database
 * @param data data to be added
 * @param user current user
 * @returns data added
 */
export const addPhaseItem = (tenantKey: any, data: any, user: any) => {
	// data added is being filtered by phase_name and operation_code to avoid duplication
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						phase_name: data.phase_name,
						operation_code: data.operation_code,
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							phase_name: "",
						},
					});
				} else {
					axios
						.post(
							`${url}/${tenantKey}/${apiURL}/add`,
							{ data, user },
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			});
	});
};

export const updatePhaseItem = (
	tenantKey: any,
	id: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null
) => {
	return new Promise((resolve, reject) => {
		console.log(id, data);
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				...AuthHeader,
				params: {
					filter: {
						_id: { $ne: id },
						phase_name: data.phase_name,
						operation_code: data.operation_code,
					},
				},
			})
			.then((result: any) => {
				if (result.data.length > 0) {
					reject({
						code: 11000,
						keyPattern: {
							phase_name: "",
						},
					});
				} else {
					axios
						.put(
							`${url}/${tenantKey}/${apiURL}/update`,
							{
								filter: { _id: id },
								data,
								initialData,
								user,
								formList,
								options: { upsert: true, new: true },
							},
							AuthHeader
						)
						.then((_data: any) => {
							resolve(_data.data);
						})
						.catch((e: any) => {
							reject(e?.response?.data?.error);
						});
				}
			});
	});
};

export const phasesReorder = (
	tenantKey: any,
	data: any,
	initialData: any,
	user: any,
	formList: any = null
) => {
	return new Promise((resolve, reject) => {
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/phasesReorder`,
				{
					data,
					initialData,
					user,
					formList,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.status);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
}

export const updatePhaseDisabled = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		console.log(id, data);
		axios
			.put(
				`${url}/${tenantKey}/${apiURL}/disable`,
				{
					filter: { _id: id },
					data,
					user,
					options: { upsert: true, new: true },
				},
				AuthHeader
			)
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const deletePhaseItem = (
	tenantKey: any,
	id: any,
	data: any,
	user: any
) => {
	return new Promise((resolve, reject) => {
		axios
			.post(
				`${url}/${tenantKey}/${apiURL}/delete`,
				{
					filter: {
						_id: id,
					},
					data: data,
					user: user,
				},
				{ ...AuthHeader }
			)
			.then((_data) => {
				resolve(true);
			})
			.catch((e: any) => {
				reject(e?.response?.data?.error);
			});
	});
};

export const getPhaseItem = (tenantKey: any, id: any) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${url}/${tenantKey}/${apiURL}`, {
				params: {
					filter: {
						_id: id,
					},
				},
				...AuthHeader,
			})
			.then((_data: any) => {
				resolve(_data.data);
			})
			.catch((e: any) => {
				reject(e);
			});
	});
};
