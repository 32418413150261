import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Menu, Layout, Image } from "antd";
import { setHomeLogo } from "../utils/company_logo";
import { GetAntIcon } from "../utils/ant_icons";
import { logout } from "../utils/utils";
import { superAdminRole } from "../utils/_exports";
import { useNavigate } from "react-router";
import Emitter from "../utils/emitter";
import { HomeContext } from "../containers/Home";

const { Sider } = Layout;

export const initialMainMenu: any = [
	{
		key: "dashboard-menu",
		label: "Dashboard",
		propTitle: "Dashboard",
		container: "dashboard",
		icon: GetAntIcon("global"),
		to: "/dashboard",
	},
	{
		key: "project-menu",
		label: "Projects",
		propTitle: "Projects",
		container: "projects",
		icon: GetAntIcon("project"),
		to: "/projects",
		child: [
			{
				propTitle: "Add new project",
				to: "/add-new-project",
				key: "add-new-project",
			},
			{
				propTitle: "Project settings",
				to: "/project-settings",
				key: "project-settings",
			},
			{
				propTitle: "Operation settings",
				to: "/operation-settings",
				key: "Operation settings",
			},
		],
	},
	{
		key: "well-menu",
		label: "Wells",
		propTitle: "Wells",
		container: "wells",
		icon: GetAntIcon("well-icon"),
		to: "/wells",
		child: [
			{
				propTitle: "Well settings",
				to: "/well-settings",
				key: "Well settings",
			},
		],
	},
];

export const initialExtraMenu: any = [
	{
		key: "help-menu",
		label: "Help center",
		propTitle: "Help center",
		className: "extra-menu-item",
		container: "helpCenter",
		to: "/help-center",
		icon: GetAntIcon("question3"),
		child: [
			{
				propTitle: "Well Delivered",
				to: "/well-delivered",
				key: "Well delivered",
			},
		],
	},
	{
		key: "notification-menu",
		label: "Notifications",
		propTitle: "Notifications",
		className: "extra-menu-item",
		icon: GetAntIcon("bell"),
	},

	{
		key: "profile-menu",
		label: "Settings",
		propTitle: "Settings",
		icon: GetAntIcon("settings"),
		className: "extra-menu-item",
		children: [
			{
				key: "user-profile",
				label: "My profile",
				propTitle: "My Profile",
				container: "profile",
				to: "/profile",
			},
			{
				key: "manage-account",
				label: "Manage account",
				propTitle: "Manage account",
			},
			{
				key: "admin-menu",
				label: "Administration",
				propTitle: "Administration",
				className: "extra-menu-item",
				container: "admin",
				to: "/administration",
				// icon: GetAntIcon("setting"),
				requires_admin: true,
				child: [
					{
						propTitle: "Programme settings",
						to: "/programme-settings",
						key: "programme-settings",
					},
				],
				// children: [
				// 	{
				// 		key: "lookup-manager",
				// 		label: "Lookup manager",
				// 		propTitle: "Lookup manager",
				// 		container: "lookupManager",
				// 		requires_admin: true,
				// 		to: "/lookup-manager",
				// 	},
				// 	{
				// 		key: "role-manager",
				// 		label: "Role manager",
				// 		propTitle: "Role manager",
				// 		container: "roleManager",
				// 		requires_admin: true,
				// 		to: "/role-manager",
				// 	},
				// ],
			},
			{ key: "some-divider", type: "divider" },
			{ key: "logout", label: "Sign out", propTitle: "Sign out" },
		],
	},
];

const MainMenu = (props: any) => {
	const context: any = useContext(HomeContext);
	const navigate = useNavigate();
	const [selectedKeys, setSelectedKeys] = useState<any>([]);

	const renderMenu = () => {
		const createMainMenu = (roles: any) => {
			if (context.allData && context.currentTenantData) {
				let email = context.allData.userInfo.user.email;
				let isAdmin =
					roles?.includes(superAdminRole) ||
					context?.currentTenantData?.tenant_admin?.includes(email);

				return initialMainMenu.map((menuItem: any) => {
					// Handle requires_admin on parent level
					if (menuItem.requires_admin && !isAdmin) {
						return null;
					}

					// Check if the menu item has children
					if (menuItem.children) {
						// Filter the child items based on requires_admin
						const filteredChildren = menuItem.children
							.map((childItem: any) => {
								if (childItem.requires_admin) {
									return roles?.includes(superAdminRole) ? childItem : null;
								}
								return childItem;
							})
							.filter(Boolean); // Remove nulls

						// Only return the parent if it has visible children
						if (filteredChildren.length > 0) {
							return { ...menuItem, children: filteredChildren };
						}
						return null;
					}

					// Return the menuItem if no specific requires_admin logic is applied
					return menuItem;
				});
			} else return [];
		};

		const handleMainMenu = async (info: any) => {
			try {
				await context.handlePageChange();
				sessionStorage.removeItem("main-tabkey");
				sessionStorage.removeItem("admin-tabkey");
				sessionStorage.removeItem("tabkey");
				if (info.item.props.to) {
					setSelectedKeys([info.key]);
					Emitter.emit("menuChange", [info.item.props]);
					navigate(info.item.props.to);
				}
			} catch {}
		};
		return (
			<Menu
				className="main-menu"
				mode="inline"
				defaultSelectedKeys={["dashboard-menu"]}
				selectedKeys={props.selectedKey || selectedKeys}
				items={createMainMenu(props.roles)}
				onClick={(info: any) => {
					handleMainMenu(info);
				}}
			></Menu>
		);
	};

	const renderExtraMenu = () => {
		const createExtraMenu = (roles: any) => {
			return initialExtraMenu.map((menuItem: any) => {
				// Handle requires_admin on parent level
				if (
					menuItem.requires_admin &&
					!roles?.includes(superAdminRole) &&
					!roles?.includes("tenant-admin")
				) {
					return null;
				}

				// Check if the menu item has children
				if (menuItem.children) {
					// Filter the child items based on requires_admin
					const filteredChildren = menuItem.children
						.map((childItem: any) => {
							if (childItem.requires_admin) {
								return roles?.includes(superAdminRole) ||
									roles.includes("tenant-admin")
									? childItem
									: null;
							}
							return childItem;
						})
						.filter(Boolean); // Remove nulls

					// Only return the parent if it has visible children
					if (filteredChildren.length > 0) {
						return { ...menuItem, children: filteredChildren };
					}
					return null;
				}

				// Return the menuItem if no specific requires_admin logic is applied
				return menuItem;
			});
		};

		const handleExtraMenu = async (info: any) => {
			try {
				await context.handlePageChange();
				sessionStorage.removeItem("admin-tabkey");
				sessionStorage.removeItem("tabkey");
				if (info.item.props.to) {
					Emitter.emit("menuChange", [info.item.props]);
					setSelectedKeys([info.key]);
					if (info.item.props.requires_admin) {
						// navigate("/admin" + info.item.props.to);
						navigate(info.item.props.to);
					} else {
						navigate(info.item.props.to);
					}
				}
				if (info.key === "logout") {
					localStorage.removeItem("idToken");
					localStorage.removeItem("accessToken");
					logout();
				}
				if (info.key === "manage-account") {
					window.open(
						"https://myaccount.microsoft.com/?ref=MeControl",
						"_blank"
					);
				}
			} catch {}
		};

		return (
			<Menu
				selectedKeys={props.selectedKey || selectedKeys}
				className="extra-menu"
				style={{ marginTop: "auto" }}
				mode="inline"
				items={createExtraMenu(props.roles)}
				onClick={(info: any) => {
					handleExtraMenu(info);
				}}
			></Menu>
		);
	};

	return (
		<>
			{props.option != "Mega" ? (
				<Sider
					className="main-sider"
					collapsed={props.collapsed}
					onCollapse={props.onCollapse}
					collapsible
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "20px",
							marginBottom: "15px",
						}}
					>
						{setHomeLogo()}
					</div>
					{renderMenu()}
					{renderExtraMenu()}
				</Sider>
			) : (
				<></>
			)}
		</>
	);
};

MainMenu.propTypes = {
	collapsed: PropTypes.bool,
	onCollapse: PropTypes.func,
	option: PropTypes.any,
	roles: PropTypes.array,
	selectedKey: PropTypes.any,
};

export default MainMenu;
