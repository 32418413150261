import React, { useEffect, useState, useContext } from "react";
import { HomeContext } from "../../containers/Home";
import CRUDTabTableModal from "../../components/CRUDTabTableModal";
import Emitter from "../../utils/emitter";
import OperationConfigModal from "../../components/Modal/OperationConfigModal";
import { getWells } from "../../services/api-server/wells";
import { checkPrivilege } from "../../utils/utils";

const ProjectOutline = (props: any) => {
	console.log("ProjectOutline", props);
	const context: any = useContext(HomeContext);

	const [otherData, setOtherData] = useState<any>(null);
	const [operationModalOpen, setOperationModalOpen] = useState<boolean>(false);
	const [operationRecord, setOperationRecord] = useState<any>(null);
	const [wellList, setWellList] = useState<any>(null);

	useEffect(() => {
		setOtherData({ project_code: props.currentProject });

		getWells(context.currentTenantKey, {
			projects: { $ne: props.currentProject },
		})
			.then((data: any) => {
				setWellList(data);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [props.currentProject]);

	useEffect(() => {
		const handleOperationModal = (data: any) => {
			setOperationModalOpen(true);
			setOperationRecord(data);
		};
		Emitter.on("operationModalOpen", handleOperationModal);
		return () => {
			Emitter.off("operationModalOpen", handleOperationModal);
		};
	}, []);

	return (
		<>
			{props.currentProject !== null && context?.allData && otherData && (
				<CRUDTabTableModal
					items={[
						{
							label: "Well management",
							key: "well_operation",
							directoryName: "Well directory",
							// addItemPrivilege: ["tenant-admin", "project-admin"],
						},
					]}
					tabKey={"project-outline"}
					otherData={otherData}
					headerName={"Project outline"}
					user={props?.user}
					userPermissions={
						checkPrivilege(props?.userRole, ["tenant-admin"])
							? null
							: props?.user?.email == props.currentProjectData?.project_manager
							? ["update", "add-existing_well", "add-operations", "delete"]
							: ["update"]
					}
				/>
			)}
			<OperationConfigModal
				open={operationModalOpen}
				setOpen={setOperationModalOpen}
				operationRecord={operationRecord}
				extraData={context.allData}
				user={props?.user}
				userRole={props?.userRole}
				currentProjectData={props.currentProjectData}
			/>
		</>
	);
};

export default ProjectOutline;
