import React, { useContext } from "react";
import type { FormInstance, PopconfirmProps } from "antd";
import { Button, Form, Input, Space, Dropdown, Popconfirm } from "antd";
import { HomeContext } from "../containers/Home";
import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

const FormButtonSave = ({
	form,
	handleFormSave,
	activeKey,
	formReset,
	setFormReset,
	setInitialForm = () => {},
	labelText = "Save",
	buttonType = null,
	saveFunction = null,
	popconfirmTitle = null,
}: {
	form: FormInstance;
	handleFormSave: any;
	activeKey?: any;
	formReset: any | null;
	setInitialForm?: any;
	setFormReset?: any;
	labelText?: string;
	buttonType?: any;
	saveFunction?: any;
	popconfirmTitle?: any;
}) => {
	const [submittable, setSubmittable] = React.useState(false);
	const [initialValues, setInitialValues] = React.useState<any>(null);
	const [disable, setDisable] = React.useState<boolean>(false);
	const [menuVisible, setMenuVisible] = React.useState<boolean>(false);
	const [popconfirmVisible, setPopconfirmVisible] =
		React.useState<boolean>(false);

	// Watch all values
	const values = Form.useWatch([], form);
	const context: any = useContext(HomeContext);

	React.useEffect(() => {
		//Some special checks for initial data
		let formValueHasData = false;
		if (values)
			formValueHasData = Object.values(values).some(
				(value) => value !== undefined
			);

		// The state of formReset need to be false AND the initialValues need to exist in order to validate the form
		if (!formReset && initialValues && formValueHasData) {
			// console.log("i-debug something change", initialValues, values);
			const valuesChanged = Object.keys(values).some((key) => {
				if (Array.isArray(values[key])) {
					if (values[key]?.length !== initialValues[key]?.length) {
						return true;
					}

					let changed = values[key].some((item: any, index: number) => {
						if (typeof item === "object") {
							let x = false;
							for (let prop in item) {
								if (item[prop] !== initialValues[key][index][prop]) {
									x = true;
									break;
								}
							}
							return x;
						} else {
							if (item !== initialValues[key][index]) {
								return true;
							}
						}
					});
					return changed;
				} else if (typeof values[key] == "object") {
					return (
						JSON.stringify(values[key]) !== JSON.stringify(initialValues[key])
					);
				} else {
					return values[key] !== initialValues[key];
				}
			});
			if (valuesChanged) {
				// If values from the initial form changed, set the submittable to true
				form.validateFields({ validateOnly: true }).then(
					() => {
						context?.handleFormUpdating(true, activeKey);
						setSubmittable(true);
						if (
							(initialValues?.business_unit !== values.business_unit ||
								initialValues?.client !== values.client) &&
							initialValues?.business_unit &&
							initialValues?.client
						) {
							setDisable(true);
						} else {
							setDisable(false);
						}
					},
					() => {
						console.log("this occurred");
						context?.handleFormUpdating(true, activeKey);
						setSubmittable(false);
					}
				);
			} else {
				context?.handleFormUpdating(false, activeKey);
				setSubmittable(false);
				if (setFormReset) setFormReset(true);
			}
		} else {
			context?.handleFormUpdating(false, activeKey);
			setSubmittable(false);
			if (setFormReset) setFormReset(true);
		}
	}, [values, formReset, initialValues]);

	React.useEffect(() => {
		if (formReset) {
			if (values) {
				setInitialValues({ ...values });
				setInitialForm({ ...values });
			}
		}
	}, [values]);

	const getButtonSave = (popconfirmExist: any = false) => {
		switch (buttonType) {
			case "dropdown-save":
				return (
					<Space>
						<Dropdown.Button
							icon={<DownOutlined />}
							menu={{
								items: [
									{
										key: "save",
										label: popconfirmExist ? (
											<CustomPopconfirm
												title={
													<div style={{ maxWidth: "300px", padding: "10px" }}>
														{popconfirmTitle}
													</div>
												}
												handleConfirm={() => {
													handleFormSave(values);
													setMenuVisible(false);
												}}
												setMenuVisible={setMenuVisible}
												onCancel={() => {
													setMenuVisible(false);
												}}
												setPopconfirmVisible={setPopconfirmVisible}
											>
												Save
											</CustomPopconfirm>
										) : (
											<>Save</>
										),
										onClick: (e) => {
											if (!disable && !popconfirmExist) {
												handleFormSave(values);
												setMenuVisible(false);
											}
										},
									},
								],
							}}
							onClick={() => {
								if (!disable && !popconfirmExist) {
									if (saveFunction) {
										saveFunction(values);
									} else {
										let redirect = "well-settings";
										handleFormSave(values, redirect);
									}
								}
							}}
							type="primary"
							htmlType="submit"
							disabled={!submittable}
							open={menuVisible}
							trigger={["click"]}
							onOpenChange={(open) => {
								if (!open) {
									if (popconfirmVisible) {
										setMenuVisible(true);
									} else {
										if (menuVisible) setMenuVisible(false);
									}
								} else {
									setMenuVisible(open);
								}
							}}
						>
							{popconfirmExist ? (
								<CustomPopconfirm
									title={
										<div style={{ maxWidth: "300px", padding: "10px" }}>
											{popconfirmTitle}
										</div>
									}
									handleConfirm={() => {
										if (!disable) {
											if (saveFunction) {
												saveFunction(values);
											} else {
												let redirect = "well-settings";
												handleFormSave(values, redirect);
											}
										}
									}}
									onCancel={() => {
										setMenuVisible(false);
									}}
								>
									{labelText || "Save"}
								</CustomPopconfirm>
							) : (
								labelText || "Save"
							)}
						</Dropdown.Button>
					</Space>
				);
			default:
				return popconfirmExist ? (
					<>
						<CustomPopconfirm
							title={
								<div style={{ maxWidth: "300px", padding: "10px" }}>
									{popconfirmTitle}
								</div>
							}
							handleConfirm={() => {
								if (!disable) {
									if (saveFunction) {
										saveFunction(values);
									} else {
										let redirect = "well-settings";
										handleFormSave(values, redirect);
									}
								}
							}}
							onCancel={() => {
								setMenuVisible(false);
							}}
						>
							<Button
								onClick={() => {
									if (!disable && !popconfirmExist) {
										handleFormSave(values);
									}
								}}
								type="primary"
								htmlType="submit"
								disabled={!submittable}
							>
								{labelText || "Save"}
							</Button>
						</CustomPopconfirm>
					</>
				) : (
					<>
						<Button
							onClick={() => {
								if (!disable && handleFormSave) {
									handleFormSave(values);
								}
							}}
							type="primary"
							htmlType="submit"
							disabled={!submittable}
						>
							{labelText || "Save"}
						</Button>
					</>
				);
		}
	};

	return <>{popconfirmTitle ? getButtonSave(true) : getButtonSave()}</>;
};

const CustomPopconfirm = ({
	handleConfirm,
	setMenuVisible,
	menuVisible,
	setPopconfirmVisible,
	...restprops
}: PopconfirmProps & {
	handleConfirm?: any;
	setMenuVisible?: any;
	menuVisible?: any;
	setPopconfirmVisible?: any;
}) => {
	return (
		<Popconfirm
			{...restprops}
			placement="topLeft"
			onConfirm={() => {
				handleConfirm();
			}}
			overlayClassName="popconfirm-danger"
			okText="Confirm"
			okType="default"
			icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
			cancelText={<span>Cancel</span>}
			cancelButtonProps={{ ghost: true }}
			onOpenChange={(open) => {
				setPopconfirmVisible && setPopconfirmVisible(open);
			}}
		/>
	);
};

export default FormButtonSave;
