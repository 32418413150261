import { Divider } from "antd";
import { Reorder } from "framer-motion";
import React, { useEffect, useState } from "react";

const ReorderList = (props: any) => {
	const [data, setData] = useState<any>(() => {
		return props.data.map((item: any) => {
			return {
				_id: item._id,
				sequence_id: item.sequence_id,
				operation_code: item.operation_code,
				[props.itemKey || ""]: item[props.itemKey || ""],
			};
		});
	});

	useEffect(() => {
		setData(() => {
			return props.data.map((item: any) => {
				return {
					_id: item._id,
					sequence_id: item.sequence_id,
					operation_code: item.operation_code,
					[props.itemKey || ""]: item[props.itemKey || ""],
				};
			});
		});
	}, [props.data]);

	return (
		<>
			{props.disabled || props.draggable === false ? (
				<ul>
					{data.map((item: any) => (
						<li
							key={item["_id"]}
							style={{
								color: props.disabled && "rgba(255,255,255,0.25)",
								padding: "5px 12px",
								border: "1px solid #434343",
								borderRadius: "2px",
								cursor: props.disabled ? "not-allowed" : "default",
							}}
						>
							{item[props.itemKey || ""]}
						</li>
					))}
				</ul>
			) : (
				<Reorder.Group
					axis={props.axis || "y"}
					values={data}
					onReorder={(data: any) => {
						console.log(data);
						props.onReorder(data);
						setData(data);
					}}
				>
					{data.map((item: any) => (
						<Reorder.Item
							key={`${item?.["_id"] || item?.[""]}`}
							className={item.disabled ? "disabled" : ""}
							value={item}
							style={{
								padding: "5px 12px",
								border: "1px solid #434343",
								borderRadius: "2px",
							}}
						>
							{item[props.itemKey || ""]}
						</Reorder.Item>
					))}
				</Reorder.Group>
			)}
		</>
	);
};

export default ReorderList;
